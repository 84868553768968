import React from "react";

function MomoLogo() {
  return (
    <div className="d-flex flex-row justify-content-end gap-3 align-items-center">
      <svg
        id="Group_39940"
        data-name="Group 39940"
        xmlns="http://www.w3.org/2000/svg"
        width="24.014"
        height="16.494"
        viewBox="0 0 24.014 16.494"
      >
        <path
          id="Path_33060"
          data-name="Path 33060"
          d="M63.683 132.063a.579.579 0 0 0 .3-.41l2.087-11.52a.934.934 0 0 0-.256-.827l-.814-.823a.933.933 0 0 0-.662-.275h-2.58a4.961 4.961 0 0 0-2.021-1.952l-.9 1.053-1.54-.616-1.36 2.286-10.12.162a.459.459 0 0 0-.319.136l-3.292 3.323a.46.46 0 0 0-.133.323v8.917a.46.46 0 0 0 .459.459h18.193l1.33.423a.58.58 0 0 0 .444-.039z"
          transform="translate(-42.073 -116.255)"
          fill="#fff"
        />
        <g
          id="Group_39939"
          data-name="Group 39939"
          transform="translate(.388 .49)"
        >
          <g id="Group_39937" data-name="Group 39937">
            <path
              id="Path_33061"
              data-name="Path 33061"
              d="M43.953 131.916l2.93-2.932a.422.422 0 0 1 .3-.124H56.9c.065.075.011.13-.018.179-.2.342-.411.681-.616 1.021-.117.194-.23.389-.349.582a.364.364 0 0 0 .034.466 2.9 2.9 0 0 0 .392.1 8.886 8.886 0 0 1 1.179.259c.5.132 1 .292 1.49.455a2.527 2.527 0 0 1 .618.249c-.019.1-.037.185-.053.274-.061.327-.126.653-.182.981s-.095.644-.151.965c-.051.289-.121.575-.174.864-.057.313-.1.627-.154.94-.037.214-.08.426-.118.639-.041.229-.079.458-.118.687l-.109.618c-.042.236-.086.472-.127.708a4.206 4.206 0 0 1-.109.641.855.855 0 0 0 .04.547 1.2 1.2 0 0 0 .865.807c.3.093.609.181.914.271l.34.1c0 .028.007.036 0 .041a.028.028 0 0 1-.019.013h-16.72a.258.258 0 0 1-.069-.009.13.13 0 0 1-.039-.026c-.006 0-.011-.011-.028-.026v-8.49a1.14 1.14 0 0 1 .334-.806"
              transform="translate(-43.619 -126.191)"
              fill="#ffc000"
            />
            <path
              id="Path_33062"
              data-name="Path 33062"
              d="M107.967 138.822a.349.349 0 0 0 .16-.281c.115-.563 1.811-9.489 1.937-10.152l.329-1.738c.019-.1.026-.2.045-.354 0 .039.005.023 0 .008-.093-.28-.1-.318-.453-.316h-1.988c-.138-.166-.168-.233-.271-.365.1-.007.165-.016.225-.016h2.149a.876.876 0 0 1 .633.26c.18.176.356.356.535.533a.95.95 0 0 1 .261.865c-.067.445-.15.887-.23 1.33-.073.4-1.466 8.481-1.508 8.718-.059.328-.124.655-.178.985a.746.746 0 0 1-.37.541 6.388 6.388 0 0 1-.962.424.8.8 0 0 1-.549-.018c-.468-.143-.934-.3-1.4-.437-.61-.185-1.223-.36-1.833-.546a5.316 5.316 0 0 1-.681-.241.851.851 0 0 1-.493-.77 1.831 1.831 0 0 1 .061-.437c.085-.457.812-4.426.865-4.716.072-.4.132-.8.2-1.194.062-.343.132-.727.2-1.079.048 0 .095.043.152.043a1.767 1.767 0 0 1 .056.871c-.046.322-.113.642-.171.962l-.943 5.176a2.112 2.112 0 0 0-.046.323.508.508 0 0 0 .389.544c.2.065.4.125.6.185.3.089.6.175.893.266.669.206 1.337.416 2.005.622a.61.61 0 0 0 .378 0"
              transform="translate(-88.356 -123.755)"
              fill="#191717"
            />
            <path
              id="Path_33063"
              data-name="Path 33063"
              d="M109.873 145.616a8.737 8.737 0 0 0 .149-2.5 13.692 13.692 0 0 1 1.769 1.042 5.453 5.453 0 0 1 .756.666 3.239 3.239 0 0 1 .826 1.634c-.187-.084-1.5-.35-2.115-.5-.375-.094-1.336-.306-1.385-.337"
              transform="translate(-93.265 -136.872)"
              fill="#d4101e"
            />
            <path
              id="Path_33064"
              data-name="Path 33064"
              d="M122.194 128.441h1.542a.371.371 0 0 1 .38.473c-.122.616-.247 1.232-.366 1.849q-.191.982-.375 1.966c-.085.458-.162.918-.245 1.376a8.213 8.213 0 0 1-.208.908c0-.151.014-.376.015-.5 0-.477-.075-2.455-.107-2.876a10.834 10.834 0 0 0-.635-3.2"
              transform="translate(-102.498 -125.876)"
              fill="#191717"
            />
            <path
              id="Path_33065"
              data-name="Path 33065"
              d="M95 123.224a2.212 2.212 0 0 0 1.109 1.921c-.035-.01-.068-.02-.1-.027l-.22-.06h-.01c-.325-.086-.63-.167-.932-.241-.368-.091-.737-.175-1.125-.267a.358.358 0 0 1 .02-.115c.14-.253.284-.5.428-.753.2-.35.413-.7.617-1.051.218-.37.432-.741.65-1.111.206-.348.416-.7.636-1.065a5.111 5.111 0 0 1 1.87.672 2.182 2.182 0 0 0-.726-.123 2.219 2.219 0 0 0-2.217 2.22z"
              transform="translate(-81.164 -119.894)"
              fill="#005788"
            />
            <path
              id="Path_33066"
              data-name="Path 33066"
              d="M101.563 123.361a2.039 2.039 0 1 0 2.037 2.039 2.041 2.041 0 0 0-2.037-2.039zm0 3.908a1.868 1.868 0 1 1 1.868-1.868 1.868 1.868 0 0 1-1.868 1.868z"
              transform="translate(-85.509 -122.07)"
              fill="#005788"
            />
            <path
              id="Path_33067"
              data-name="Path 33067"
              d="M111.064 133.255a4.215 4.215 0 0 0-.545-.88 9.882 9.882 0 0 0-3.879-2.428 2.216 2.216 0 0 0 2.082-3.458 7.139 7.139 0 0 1 .7.786 7.529 7.529 0 0 1 .628 1.068 8.926 8.926 0 0 1 .619 1.8 15.06 15.06 0 0 1 .38 2.647l.015.465z"
              transform="translate(-90.844 -124.414)"
              fill="#005788"
            />
            <path
              id="Path_33068"
              data-name="Path 33068"
              d="M109.708 119.06l.78-.849a5.2 5.2 0 0 1 .97.8 6.03 6.03 0 0 1 .743 1.018 7.13 7.13 0 0 1 .709 1.916 7.887 7.887 0 0 1 .195 1.448 6.865 6.865 0 0 0-1.182-2.43 5.624 5.624 0 0 0-1.081-1.166 5.362 5.362 0 0 0-1.037-.675c-.027-.014-.051-.033-.1-.063"
              transform="translate(-93.142 -118.211)"
              fill="#005788"
            />
            <path
              id="Path_33069"
              data-name="Path 33069"
              d="M116.147 169.653l.12.236c-.1.228-.018.528-.261.724a.608.608 0 0 1-.225-.034 1.884 1.884 0 0 0-.625-.222c-.27-.031-.459-.058-.586-.257a2.218 2.218 0 0 1 .1-.7l.189-.118 1.285.373"
              transform="translate(-96.781 -156.479)"
              fill="#191717"
            />
            <path
              id="Path_33070"
              data-name="Path 33070"
              d="M115.536 164.885c.087-.2.038-.451.183-.652a.607.607 0 0 1 .348.006c.276.078.555.142.833.213a.468.468 0 0 1 .333.229 2.289 2.289 0 0 1-.1.693.335.335 0 0 1-.382.075c-.294-.1-.6-.167-.9-.249a.375.375 0 0 1-.318-.316"
              transform="translate(-97.509 -152.68)"
              fill="#191717"
            />
            <path
              id="Path_33071"
              data-name="Path 33071"
              d="M116.914 158.9l1.282.312.126.135a1.518 1.518 0 0 1-.089.64c-.052.187-.146.223-.341.171-.352-.094-.706-.184-1.087-.283l-.188-.17.125-.667.172-.139"
              transform="translate(-98.319 -148.701)"
              fill="#191717"
            />
            <path
              id="Path_33072"
              data-name="Path 33072"
              d="M107.382 161.868a.308.308 0 0 1 .3-.047c.334.1.673.179 1.01.267l.147.156a2.3 2.3 0 0 1-.1.646c-.006.045-.065.083-.1.122a.6.6 0 0 1-.306-.022c-.266-.083-.538-.149-.808-.22a.436.436 0 0 1-.292-.216 1.581 1.581 0 0 1 .148-.686"
              transform="translate(-91.287 -150.876)"
              fill="#191717"
            />
            <path
              id="Path_33073"
              data-name="Path 33073"
              d="M106.251 166.968l.16-.151c.4.061.783.226 1.185.307l.144.151a1.781 1.781 0 0 1-.114.744l-.2.072a12.518 12.518 0 0 1-1.209-.39l-.069-.195.1-.538"
              transform="translate(-90.474 -154.633)"
              fill="#191717"
            />
            <path
              id="Path_33074"
              data-name="Path 33074"
              d="M108.448 157.629c-.18-.306-.014-.579.06-.849l.176-.063c.306.075.614.151.922.225a.489.489 0 0 1 .366.237 2.028 2.028 0 0 1-.123.625.232.232 0 0 1-.294.129c-.366-.1-.731-.2-1.107-.3"
              transform="translate(-92.137 -147.065)"
              fill="#191717"
            />
            <path
              id="Path_33075"
              data-name="Path 33075"
              d="M55.943 154.47a15.637 15.637 0 0 1-.025-.875h-.009a12.05 12.05 0 0 1-.247.8l-.3.9h-.294l-.278-.89c-.08-.266-.154-.55-.21-.81h-.01c-.01.272-.022.6-.037.887l-.046.838h-.356l.139-2.084h.5l.272.838c.074.244.139.492.192.723h.009c.055-.226.127-.482.207-.727l.288-.835h.495l.121 2.084h-.371z"
              transform="translate(-51.496 -144.456)"
              fill="#191717"
            />
            <path
              id="Path_33076"
              data-name="Path 33076"
              d="M65.685 156.181a.746.746 0 0 1-.773.8.728.728 0 0 1-.755-.776.743.743 0 0 1 .779-.8.722.722 0 0 1 .748.773m-1.135.016c0 .291.142.51.374.51.217 0 .368-.213.368-.516 0-.235-.105-.5-.365-.5s-.377.26-.377.51"
              transform="translate(-59.01 -146.084)"
              fill="#191717"
            />
            <path
              id="Path_33077"
              data-name="Path 33077"
              d="M71.446 154.988c.006-.1.013-.269.013-.423v-1.772h.38v.9h.006a.544.544 0 0 1 .482-.241c.368 0 .631.306.628.764a.721.721 0 0 1-.683.81.518.518 0 0 1-.476-.26h-.006l-.018.226zm.393-.621a.416.416 0 0 0 .009.09.351.351 0 0 0 .337.266c.238 0 .384-.192.384-.495 0-.266-.127-.482-.38-.482a.356.356 0 0 0-.337.278.466.466 0 0 0-.012.1z"
              transform="translate(-64.471 -144.124)"
              fill="#191717"
            />
            <path
              id="Path_33078"
              data-name="Path 33078"
              d="M79.05 153.247a.214.214 0 1 1-.213-.207.2.2 0 0 1 .213.207m-.405.421h.384v1.506h-.384z"
              transform="translate(-69.849 -144.31)"
              fill="#191717"
            />
            <path
              id="Rectangle_22471"
              data-name="Rectangle 22471"
              transform="translate(9.588 8.668)"
              fill="#191717"
              d="M0 0h.383v2.195H0z"
            />
            <path
              id="Path_33079"
              data-name="Path 33079"
              d="M85.019 156.3a.409.409 0 0 0 .464.39 1.133 1.133 0 0 0 .418-.068l.055.263a1.416 1.416 0 0 1-.526.093.712.712 0 0 1-.776-.761.746.746 0 0 1 .736-.81.654.654 0 0 1 .649.733.952.952 0 0 1-.012.161zm.662-.266a.324.324 0 0 0-.312-.368.372.372 0 0 0-.349.368z"
              transform="translate(-74.368 -146.084)"
              fill="#191717"
            />
            <path
              id="Path_33080"
              data-name="Path 33080"
              d="M55.943 165.5a15.637 15.637 0 0 1-.025-.875h-.009a12.05 12.05 0 0 1-.247.8l-.3.9h-.294l-.278-.891c-.08-.266-.154-.55-.21-.81h-.01c-.01.272-.022.6-.037.887l-.046.838h-.356l.139-2.084h.5l.272.838c.074.244.139.492.192.723h.009c.055-.226.127-.482.207-.727l.288-.835h.495l.121 2.084h-.371z"
              transform="translate(-51.496 -152.719)"
              fill="#191717"
            />
            <path
              id="Path_33081"
              data-name="Path 33081"
              d="M65.685 167.207a.746.746 0 0 1-.773.8.728.728 0 0 1-.755-.776.743.743 0 0 1 .779-.8.722.722 0 0 1 .748.773m-1.135.016c0 .291.142.51.374.51.217 0 .368-.213.368-.516 0-.235-.105-.5-.365-.5s-.377.26-.377.51"
              transform="translate(-59.01 -154.346)"
              fill="#191717"
            />
            <path
              id="Path_33082"
              data-name="Path 33082"
              d="M71.459 166.916c0-.173 0-.319-.013-.448h.334l.019.226h.01a.542.542 0 0 1 .479-.26c.263 0 .535.17.535.646v.894h-.38v-.85c0-.217-.08-.38-.288-.38a.319.319 0 0 0-.3.223.388.388 0 0 0-.016.124v.884h-.383z"
              transform="translate(-64.471 -154.346)"
              fill="#191717"
            />
            <path
              id="Path_33083"
              data-name="Path 33083"
              d="M78.532 167.327a.409.409 0 0 0 .464.39 1.131 1.131 0 0 0 .418-.068l.055.263a1.416 1.416 0 0 1-.526.093.712.712 0 0 1-.776-.761.746.746 0 0 1 .736-.81.655.655 0 0 1 .649.733.92.92 0 0 1-.013.161zm.662-.266a.324.324 0 0 0-.312-.368.372.372 0 0 0-.349.368z"
              transform="translate(-69.507 -154.346)"
              fill="#191717"
            />
            <path
              id="Path_33084"
              data-name="Path 33084"
              d="M84.578 166.569l.272.807c.031.093.062.207.083.294h.009c.025-.087.053-.2.08-.3l.235-.8h.408l-.377 1.027a2.257 2.257 0 0 1-.525.968.767.767 0 0 1-.4.2l-.086-.322a.653.653 0 0 0 .223-.105.667.667 0 0 0 .207-.238.156.156 0 0 0 .025-.068.161.161 0 0 0-.022-.074l-.55-1.382z"
              transform="translate(-73.999 -154.447)"
              fill="#191717"
            />
            <path
              id="Path_33085"
              data-name="Path 33085"
              d="M57.241 139.044c-.019-.41-.042-.9-.038-1.334h-.014c-.1.387-.235.815-.377 1.216l-.462 1.376h-.45l-.424-1.357a16.292 16.292 0 0 1-.32-1.235h-.01c-.014.415-.033.919-.056 1.352l-.071 1.277h-.539l.212-3.176h.763l.415 1.277c.113.372.212.749.292 1.1h.014c.085-.344.193-.735.316-1.107l.438-1.272h.754l.184 3.176H57.3z"
              transform="translate(-51.758 -132.413)"
              fill="#191717"
            />
            <path
              id="Path_33086"
              data-name="Path 33086"
              d="M69.841 137.65h-.9v-.485h2.4v.485h-.921v2.691h-.58z"
              transform="translate(-62.59 -132.414)"
              fill="#191717"
            />
            <path
              id="Path_33087"
              data-name="Path 33087"
              d="M79.989 140.341v-3.176h.66l.82 1.362a8.73 8.73 0 0 1 .542 1.065h.01a14.377 14.377 0 0 1-.052-1.319v-1.107h.537v3.176h-.6l-.829-1.395a11.046 11.046 0 0 1-.57-1.1h-.014c.024.41.028.829.028 1.352v1.136z"
              transform="translate(-70.872 -132.414)"
              fill="#191717"
            />
          </g>
          <g
            id="Group_39938"
            data-name="Group 39938"
            transform="translate(14.974 1.843)"
            fill="#005788"
          >
            <path
              id="Path_33088"
              data-name="Path 33088"
              d="M104.518 132.529a1.186 1.186 0 0 1-.185.307.59.59 0 0 1-.389.233.513.513 0 0 1-.389-.138.546.546 0 0 1-.025-.819.732.732 0 0 1 .231-.189l.113.163a.584.584 0 0 0-.2.157.345.345 0 0 0 .511.463.327.327 0 0 0 .065-.1l-.168-.145-.107.124-.142-.122.257-.3z"
              transform="translate(-103.357 -130.329)"
            />
            <path
              id="Path_33089"
              data-name="Path 33089"
              d="M105.575 128.987l.308.265.257-.3-.308-.265.156-.181.8.691-.156.181-.336-.289-.257.3.336.289-.157.182-.8-.691z"
              transform="translate(-104.901 -127.77)"
            />
            <path
              id="Path_33090"
              data-name="Path 33090"
              d="M110.045 126.226a.68.68 0 0 1-.143.236.5.5 0 0 1-.763.075.54.54 0 0 1-.049-.8.625.625 0 0 1 .206-.167l.112.161a.521.521 0 0 0-.161.127.348.348 0 0 0 .528.453.586.586 0 0 0 .1-.174z"
              transform="translate(-107.536 -125.565)"
            />
          </g>
        </g>
      </svg>

      <svg
        id="Group_39936"
        data-name="Group 39936"
        xmlns="http://www.w3.org/2000/svg"
        width="29.316"
        height="15.856"
        viewBox="0 0 29.316 15.856"
      >
        <path
          id="Path_33038"
          data-name="Path 33038"
          d="M261.886 129.15a.072.072 0 0 1-.072-.072V125.4a.072.072 0 1 1 .144 0v3.674a.072.072 0 0 1-.072.072"
          transform="translate(-244.744 -123.376)"
          fill="#96989c"
        />
        <path
          id="Path_33039"
          data-name="Path 33039"
          d="M223.031 133.383h-29.316v-15.856h29.316zm-29.173-.144h29.029V117.67h-29.029z"
          transform="translate(-193.715 -117.527)"
          fill="#96989c"
        />
        <path
          id="Path_33040"
          data-name="Path 33040"
          d="M294.448 129.629a1.21 1.21 0 0 1-.4.861 1.179 1.179 0 0 1-1.268.213 1.122 1.122 0 0 1-.707-.948 1.537 1.537 0 0 1 .064-.735 1.147 1.147 0 0 1 1.261-.808c.642.07 1.05.558 1.05 1.418m-.63-.135a2.959 2.959 0 0 0-.13-.459.466.466 0 0 0-.494-.261.441.441 0 0 0-.408.3 1.1 1.1 0 0 0 .02.868.487.487 0 0 0 .534.277.452.452 0 0 0 .4-.336c.034-.114.047-.233.076-.385"
          transform="translate(-267.404 -125.527)"
          fill="#093065"
          fill-rule="evenodd"
        />
        <path
          id="Path_33041"
          data-name="Path 33041"
          d="M284.2 129.044a1.136 1.136 0 0 1-.143-.054 1.236 1.236 0 0 0-.864-.136.743.743 0 0 0-.547.921.7.7 0 0 0 .807.584c.16-.006.206-.062.2-.22-.006-.185-.021-.369-.033-.577a3.261 3.261 0 0 1 .412.032.276.276 0 0 1 .226.3v.336a.578.578 0 0 1-.511.642 1.521 1.521 0 0 1-1.185-.17 1.2 1.2 0 0 1-.571-1.259 1.238 1.238 0 0 1 .868-1.067 1.584 1.584 0 0 1 .844-.08.577.577 0 0 1 .5.749"
          transform="translate(-259.853 -125.577)"
          fill="#093065"
          fill-rule="evenodd"
        />
        <path
          id="Path_33042"
          data-name="Path 33042"
          d="M270.837 128.6a.468.468 0 0 1-.508.623.821.821 0 0 1-.939-.678.682.682 0 0 1-.013-.136v-1.668a.609.609 0 0 1 .344-.538.937.937 0 0 1 .276-.056v.566h.846a2.271 2.271 0 0 1 0 .3.33.33 0 0 1-.344.265c-.157 0-.315 0-.473.007l-.023.021v.992a.314.314 0 0 0 .358.348c.155 0 .309-.031.475-.049"
          transform="translate(-250.41 -123.983)"
          fill="#093065"
          fill-rule="evenodd"
        />
        <path
          id="Path_33043"
          data-name="Path 33043"
          d="M284.608 140.1a1.926 1.926 0 0 0 1.633.864 1.983 1.983 0 0 0 1.659-.878 1.151 1.151 0 0 1-.189.9 1.775 1.775 0 0 1-1.09.643 1.882 1.882 0 0 1-1.785-.583.9.9 0 0 1-.255-.667v-.26l.029-.018"
          transform="translate(-261.803 -134.433)"
          fill="#093065"
          fill-rule="evenodd"
        />
        <path
          id="Path_33044"
          data-name="Path 33044"
          d="M278.044 130.572a2.646 2.646 0 0 1-.03.311.241.241 0 0 1-.206.192.684.684 0 0 1-.882-.689c-.007-.547 0-1.095 0-1.642 0-.024 0-.049.005-.094a1.982 1.982 0 0 1 .331.021.384.384 0 0 1 .291.424c.005.373 0 .746 0 1.12 0 .275.075.35.353.357h.137"
          transform="translate(-256.066 -125.862)"
          fill="#093065"
          fill-rule="evenodd"
        />
        <path
          id="Path_33045"
          data-name="Path 33045"
          d="M294.747 124.251a1.345 1.345 0 0 0-1.1-.536 1.36 1.36 0 0 0-1.1.53.739.739 0 0 1 .162-.669 1.392 1.392 0 0 1 1.829-.035.744.744 0 0 1 .206.71"
          transform="translate(-267.765 -121.789)"
          fill="#093065"
          fill-rule="evenodd"
        />
        <path
          id="Path_33046"
          data-name="Path 33046"
          d="M277.434 125.258a.38.38 0 0 1-.375.372.37.37 0 0 1-.362-.369.366.366 0 0 1 .371-.37.375.375 0 0 1 .366.367"
          transform="translate(-255.896 -123.045)"
          fill="#093065"
          fill-rule="evenodd"
        />
        <path
          id="Path_33047"
          data-name="Path 33047"
          d="M224.49 123.9a.409.409 0 0 0-.411.405.4.4 0 0 0 .81 0 .4.4 0 0 0-.4-.408"
          transform="translate(-216.468 -122.304)"
          fill="#e1051d"
        />
        <path
          id="Path_33048"
          data-name="Path 33048"
          d="M224.42 130.483h.637v-2.536l-.637.1z"
          transform="translate(-216.724 -125.335)"
          fill="#e1051d"
        />
        <path
          id="Path_33049"
          data-name="Path 33049"
          d="M215.574 127.767a1.888 1.888 0 0 0-.859.226l-.072.037.171.382.1-.046a1.393 1.393 0 0 1 .6-.141c.231.009.326.115.326.336v.13h-.378c-.606 0-.984.322-.994.82a.907.907 0 0 0 .946.883 1.487 1.487 0 0 0 1.073-.4v-1.354a.825.825 0 0 0-.91-.876m.263 2.1a.627.627 0 0 1-.331.082.369.369 0 0 1-.367-.425.4.4 0 0 1 .442-.4h.255z"
          transform="translate(-209.264 -125.2)"
          fill="#e1051d"
        />
        <path
          id="Path_33050"
          data-name="Path 33050"
          d="M242.118 130.005a.48.48 0 0 1-.37-.14.95.95 0 0 1-.151-.649h1.373v-.063c-.012-.9-.318-1.308-.989-1.308-.748 0-1.04.689-1.046 1.336a1.368 1.368 0 0 0 .432 1.056 1.06 1.06 0 0 0 .686.222 1.534 1.534 0 0 0 .452-.067 1.818 1.818 0 0 0 .479-.233l-.18-.369a1.232 1.232 0 0 1-.686.216m-.138-1.686c.283 0 .325.307.325.5h-.69c.011-.246.113-.5.365-.5"
          transform="translate(-229.098 -125.259)"
          fill="#e1051d"
        />
        <path
          id="Path_33051"
          data-name="Path 33051"
          d="M228.992 128.286v2.084h.649v-1.961a.724.724 0 0 1 .435-.162.817.817 0 0 1 .261.027l.18-.4a1.1 1.1 0 0 0-.429-.077 1.479 1.479 0 0 0-1.1.493"
          transform="translate(-220.149 -125.22)"
          fill="#e1051d"
        />
        <path
          id="Path_33052"
          data-name="Path 33052"
          d="M251.573 127.983c-.265-.02-.32-.137-.32-.33v-2.509l-.639.109v2.359a.714.714 0 0 0 .812.8 1.576 1.576 0 0 0 .244-.026v-.4h-.1"
          transform="translate(-236.351 -123.234)"
          fill="#e1051d"
        />
        <path
          id="Path_33053"
          data-name="Path 33053"
          d="M236.414 125.158l-.653.1v2.37c0 .531.31.782.83.782a1.4 1.4 0 0 0 .243-.024V128h-.092c-.274-.01-.328-.161-.328-.335v-1.334h.42v-.468h-.42z"
          transform="translate(-225.222 -123.245)"
          fill="#e1051d"
        />
        <path
          id="Path_33054"
          data-name="Path 33054"
          d="M199.853 128.623a2.528 2.528 0 0 0 .508-.268l.041-.026a3.787 3.787 0 0 0 .479-.361 2.191 2.191 0 0 0 .7-.994 1.251 1.251 0 0 0 .034-.726.937.937 0 0 0-.365-.495 1.054 1.054 0 0 0-.663-.2 2.329 2.329 0 0 0-1.251.441l-.014.009-.04.025a.345.345 0 0 1-.035.023 2.587 2.587 0 0 0-.259.2 1.142 1.142 0 0 0-.39.746.348.348 0 0 0 .136.266.448.448 0 0 0 .283.09 1.361 1.361 0 0 0 .65-.23l.033-.022.089-.06.03-.02a3.483 3.483 0 0 1 .779-.43.607.607 0 0 1 .2-.037.378.378 0 0 1 .085.009.265.265 0 0 1 .188.146.671.671 0 0 1-.04.539 2.349 2.349 0 0 1-.623.773 2.472 2.472 0 0 1-.408.291l-.011.006a1.49 1.49 0 0 1-.189.092h-.009l-.053.02c-.285.076-.112-.175-.112-.175a2.159 2.159 0 0 1 .195-.2l.119-.114.009-.009a.294.294 0 0 0 .114-.215.253.253 0 0 0-.261-.215h-.009a.525.525 0 0 0-.306.129.993.993 0 0 0-.2.231.665.665 0 0 0-.08.673.339.339 0 0 0 .309.148 1.054 1.054 0 0 0 .35-.072"
          transform="translate(-197.37 -123.545)"
          fill="#e1051d"
        />
        <path
          id="Rectangle_22469"
          data-name="Rectangle 22469"
          transform="translate(.143 8.439)"
          fill="#093065"
          d="M0 0h14.515v7.312H0z"
        />
        <path
          id="Rectangle_22470"
          data-name="Rectangle 22470"
          transform="translate(14.658 8.439)"
          fill="#e1051d"
          d="M0 0h14.515v7.312H0z"
        />
        <path
          id="Path_33055"
          data-name="Path 33055"
          d="M221.371 158.4a1.24 1.24 0 0 0-1.069.678h-.013c-.032-.422-.237-.678-.679-.678a1.224 1.224 0 0 0-1.056.71h-.013a5.754 5.754 0 0 0 .122-.627h-.915l-.57 3.136h.941l.038-.23c.314-1.779.691-2.157.96-2.157.154 0 .218.089.218.256a1.076 1.076 0 0 1-.019.186c0 .006-.006.013-.006.019l-.358 1.926h.947l.038-.23c.314-1.773.685-2.157.967-2.157.147 0 .211.083.211.25a1.55 1.55 0 0 1-.026.211l-.352 1.926h.934l.352-1.939a2.461 2.461 0 0 0 .045-.454c0-.576-.269-.826-.7-.826"
          transform="translate(-211.297 -148.154)"
          fill="#fff"
        />
        <path
          id="Path_33056"
          data-name="Path 33056"
          d="M239.172 158.4a1.877 1.877 0 0 0-1.824 1.99 1.231 1.231 0 0 0 1.3 1.305 1.814 1.814 0 0 0 1.8-1.977 1.223 1.223 0 0 0-1.28-1.318m-.435 2.573c-.288 0-.416-.243-.416-.608 0-.525.288-1.242.768-1.242.281 0 .41.218.41.589 0 .525-.25 1.261-.762 1.261"
          transform="translate(-226.411 -148.154)"
          fill="#fff"
        />
        <path
          id="Path_33057"
          data-name="Path 33057"
          d="M252.349 158.4a1.266 1.266 0 0 0-1.088.717h-.013a5.807 5.807 0 0 0 .122-.634h-.915l-.57 3.136h1.187a2.042 2.042 0 0 1-.624-1.206l.944-.866a2.348 2.348 0 0 0 .041.529 2.38 2.38 0 0 0 .635 1.2 2.415 2.415 0 0 0 .414.341h.16l.365-1.99a2.444 2.444 0 0 0 .039-.41c0-.537-.25-.819-.7-.819m-.707 1.51c.08-.073.171-.151.28-.237a3.072 3.072 0 0 0 .1 1.3 2.272 2.272 0 0 1-.38-1.062"
          transform="translate(-235.805 -148.154)"
          fill="#fff"
        />
        <path
          id="Path_33058"
          data-name="Path 33058"
          d="M265.376 160.626a1.3 1.3 0 0 1-.794.314c-.314 0-.416-.179-.448-.467 1.043-.1 1.869-.467 1.869-1.229 0-.525-.365-.845-1-.845a1.883 1.883 0 0 0-1.843 2 1.174 1.174 0 0 0 1.261 1.3 1.99 1.99 0 0 0 1.293-.441zm-.531-1.5c.173 0 .256.083.256.237 0 .281-.352.473-.928.525.09-.39.314-.762.672-.762"
          transform="translate(-245.754 -148.154)"
          fill="#fff"
        />
        <path
          id="Path_33059"
          data-name="Path 33059"
          d="M274.746 158.731l-.736 1.632c-.083.2-.154.429-.166.467h-.013c-.007-.038-.013-.269-.039-.467l-.224-1.632h-1l.614 3.091c-.262.506-.435.7-.858.7a.727.727 0 0 1-.346-.07l-.313.755a1.323 1.323 0 0 0 .627.115c.844 0 1.273-.442 1.792-1.44l1.664-3.149z"
          transform="translate(-252.123 -148.403)"
          fill="#fff"
        />
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28.337"
        height="18.261"
        viewBox="0 0 28.337 18.261"
      >
        <g id="Group_39945" data-name="Group 39945">
          <g id="Group_39944" data-name="Group 39944">
            <g
              id="Group_39942"
              data-name="Group 39942"
              transform="translate(8.622)"
            >
              <path
                id="Path_33091"
                data-name="Path 33091"
                d="M396.294 132.617a6.468 6.468 0 0 1-1.55.652 5.337 5.337 0 0 1-.518.122 5.451 5.451 0 0 1-1.592.063h-.011a1.482 1.482 0 0 1-.15-.021l-.008.016a.7.7 0 0 0-.077.006h-.005c-.013 0-.026-.024-.036-.026s-.018.015-.026.012-.008.015-.008.015h-.021c-.005-.111-.008-.03-.008-.03l1.941-1.4.518-.374.388-.281a4.88 4.88 0 0 0 1.163 1.246z"
                transform="translate(-392.285 -126.698)"
                fill="#4e5822"
              />
              <path
                id="Path_33092"
                data-name="Path 33092"
                d="M403.449 125.213a9 9 0 0 1-2.5 1.942 9.212 9.212 0 0 1-.518.246 6.779 6.779 0 0 1-1.941.531 14.337 14.337 0 0 1-3.668-.11c-.181-.026-.352-.035-.518-.065-1.035-.181-1.771-.422-1.916-.422h.036a.17.17 0 0 0 .036 0h.087c.044.008.093.012.15.017h.013a5.457 5.457 0 0 0 1.592-.063c.166-.031.339-.07.518-.122a6.479 6.479 0 0 0 1.55-.653c.072-.042.145-.083.218-.13 2.5-1.986 2.89-3.322 2.89-3.322l.95.515.518.282z"
                transform="translate(-392.363 -120.469)"
                fill="#64a431"
              />
              <g
                id="Group_39941"
                data-name="Group 39941"
                transform="translate(1.94)"
                fill="#dd1120"
              >
                <path
                  id="Path_33093"
                  data-name="Path 33093"
                  d="M405.478 112.731h-4.263a1.19 1.19 0 0 0-1.191 1.189v3.98l.518-.365v-3.615a.673.673 0 0 1 .673-.671h4.263a.673.673 0 0 1 .673.671v1.752l.517.282v-2.034a1.192 1.192 0 0 0-1.19-1.189z"
                  transform="translate(-400.024 -112.731)"
                />
                <path
                  id="Path_33094"
                  data-name="Path 33094"
                  d="M406.157 140.558v2.762h-5.609v-2.387a12.471 12.471 0 0 1-.518-.085v4.525a1.193 1.193 0 0 0 1.191 1.191h4.263a1.194 1.194 0 0 0 1.19-1.191v-5.06c-.165.082-.339.165-.517.245zm-4.047 4.453h-.7v-.7h.7zm1.713.062h-1.079v-1.079h1.079zm1.473-.063h-.696v-.7h.694z"
                  transform="translate(-400.029 -133.398)"
                />
              </g>
            </g>
            <g
              id="Group_39943"
              data-name="Group 39943"
              transform="translate(0 15.144)"
              fill="#dd1120"
            >
              <path
                id="Path_33095"
                data-name="Path 33095"
                d="M359.581 176.54l-.424 1.323-.443-1.323h-.826l.931 2.221h.646l.9-2.221z"
                transform="translate(-357.887 -175.689)"
              />
              <path
                id="Path_33096"
                data-name="Path 33096"
                d="M370.119 176.972a.971.971 0 0 0-.361-.452 1.075 1.075 0 0 0-.641-.18 1.3 1.3 0 0 0-.521.109.918.918 0 0 0-.417.384 1.277 1.277 0 0 0-.162.655 1.409 1.409 0 0 0 .074.467 1.053 1.053 0 0 0 .217.37 1.007 1.007 0 0 0 .346.244 1.172 1.172 0 0 0 .465.087 1.19 1.19 0 0 0 .468-.088 1 1 0 0 0 .347-.244 1.051 1.051 0 0 0 .217-.37 1.41 1.41 0 0 0-.03-.982zm-.727.946a.311.311 0 0 1-.544 0 .9.9 0 0 1-.088-.431.812.812 0 0 1 .09-.411.312.312 0 0 1 .537 0 .8.8 0 0 1 .09.411.917.917 0 0 1-.085.431z"
                transform="translate(-365.476 -175.539)"
              />
              <path
                id="Path_33097"
                data-name="Path 33097"
                d="M379.344 173.365v.813a.666.666 0 0 0-.183-.052 1.388 1.388 0 0 0-.21-.016 1.01 1.01 0 0 0-.456.113.877.877 0 0 0-.375.4 1.373 1.373 0 0 0-.149.646 1.564 1.564 0 0 0 .066.46 1.155 1.155 0 0 0 .185.368.861.861 0 0 0 .29.244.816.816 0 0 0 .381.087.672.672 0 0 0 .33-.067.685.685 0 0 0 .185-.149l.036.171h.655v-3.016zm0 2.366a.3.3 0 0 1-.106.086.339.339 0 0 1-.156.036.3.3 0 0 1-.27-.161.805.805 0 0 1-.1-.431 1.285 1.285 0 0 1 .023-.219.468.468 0 0 1 .124-.239.337.337 0 0 1 .26-.111.569.569 0 0 1 .226.054z"
                transform="translate(-372.937 -173.31)"
              />
              <path
                id="Path_33098"
                data-name="Path 33098"
                d="M389.525 176.547a.962.962 0 0 0-.654-.212 1.635 1.635 0 0 0-.244.023 1.881 1.881 0 0 0-.327.088 1.36 1.36 0 0 0-.277.129l.217.478a1.262 1.262 0 0 1 .253-.115.912.912 0 0 1 .275-.043c.184 0 .289.083.289.248v.077c-.222.015-.406.03-.487.045a.936.936 0 0 0-.244.056.924.924 0 0 0-.243.134.677.677 0 0 0-.181.214.627.627 0 0 0-.071.3.7.7 0 0 0 .052.28.647.647 0 0 0 .142.21.6.6 0 0 0 .214.133.781.781 0 0 0 .269.045.87.87 0 0 0 .339-.061.777.777 0 0 0 .235-.147l.059.171h.632v-1.4a.822.822 0 0 0-.248-.653zm-.47 1.472c-.111.027-.086.049-.133.068a.449.449 0 0 1-.153.027.254.254 0 0 1-.177-.056.188.188 0 0 1-.063-.142.275.275 0 0 1 .053-.174.338.338 0 0 1 .2-.1 2.249 2.249 0 0 1 .275-.032z"
                transform="translate(-380.323 -175.535)"
              />
              <path
                id="Path_33099"
                data-name="Path 33099"
                d="M397.913 173.7a.848.848 0 0 1 .178.018.644.644 0 0 1 .149.05l.077-.515a1.826 1.826 0 0 0-.25-.075 1.578 1.578 0 0 0-.363-.038.848.848 0 0 0-.6.206.794.794 0 0 0-.233.589v.033h-.242v.555h.284v1.677l.755.005v-1.663h.442l-.01-.555h-.452v-.045c.003-.156.097-.242.265-.242z"
                transform="translate(-386.92 -173.145)"
              />
              <path
                id="Path_33100"
                data-name="Path 33100"
                d="M405.185 176.964a.969.969 0 0 0-.361-.452 1.074 1.074 0 0 0-.641-.18 1.3 1.3 0 0 0-.521.109.915.915 0 0 0-.418.384 1.285 1.285 0 0 0-.162.655 1.429 1.429 0 0 0 .074.467 1.058 1.058 0 0 0 .217.37 1 1 0 0 0 .345.244 1.172 1.172 0 0 0 .465.087 1.185 1.185 0 0 0 .467-.088 1 1 0 0 0 .348-.244 1.057 1.057 0 0 0 .216-.37 1.4 1.4 0 0 0 .074-.467 1.436 1.436 0 0 0-.103-.515zm-.727.946a.311.311 0 0 1-.543 0 .9.9 0 0 1-.088-.431.807.807 0 0 1 .09-.411.291.291 0 0 1 .266-.153.3.3 0 0 1 .271.153.81.81 0 0 1 .09.411.907.907 0 0 1-.086.431z"
                transform="translate(-391.752 -175.533)"
              />
              <path
                id="Path_33101"
                data-name="Path 33101"
                d="M415.308 176.541a.786.786 0 0 0-.571-.212.859.859 0 0 0-.361.072.985.985 0 0 0-.262.167l-.032-.19h-.664v2.222h.754v-1.54a.9.9 0 0 1 .142-.1.348.348 0 0 1 .178-.045.241.241 0 0 1 .206.094.455.455 0 0 1 .07.271v1.32h.759v-1.4a.877.877 0 0 0-.219-.659z"
                transform="translate(-399.498 -175.531)"
              />
              <path
                id="Path_33102"
                data-name="Path 33102"
                d="M424.858 177.99a.653.653 0 0 1-.316.07.5.5 0 0 1-.35-.108.425.425 0 0 1-.129-.312h1.359a1.516 1.516 0 0 0-.28-.989.927.927 0 0 0-.745-.325 1.265 1.265 0 0 0-.478.1.832.832 0 0 0-.422.381 1.314 1.314 0 0 0-.169.68 1.411 1.411 0 0 0 .084.51.99.99 0 0 0 .232.361.947.947 0 0 0 .35.215 1.346 1.346 0 0 0 .441.069 2.208 2.208 0 0 0 .3-.025 1.243 1.243 0 0 0 .336-.1.869.869 0 0 0 .26-.17l-.221-.533a1.525 1.525 0 0 1-.252.176zm-.687-1.054a.293.293 0 0 1 .231-.1.272.272 0 0 1 .219.109.481.481 0 0 1 .1.28h-.65a.476.476 0 0 1 .099-.289z"
                transform="translate(-406.923 -175.529)"
              />
              <path
                id="Path_33103"
                data-name="Path 33103"
                d="M437.111 177.976a.6.6 0 0 1-.287.063.411.411 0 0 1-.334-.14.677.677 0 0 1-.117-.438.7.7 0 0 1 .1-.411.318.318 0 0 1 .271-.135.285.285 0 0 1 .208.086.868.868 0 0 1 .158.235l.519-.334a2.5 2.5 0 0 0-.134-.212.879.879 0 0 0-.287-.25.931.931 0 0 0-.483-.115 1.4 1.4 0 0 0-.5.1.869.869 0 0 0-.429.384 1.292 1.292 0 0 0-.167.682 1.148 1.148 0 0 0 .3.853 1.1 1.1 0 0 0 .81.294 1.351 1.351 0 0 0 .515-.088 1.258 1.258 0 0 0 .321-.179l-.239-.56a1.048 1.048 0 0 1-.225.165z"
                transform="translate(-416.141 -175.526)"
              />
              <path
                id="Path_33104"
                data-name="Path 33104"
                d="M446.022 176.533a.963.963 0 0 0-.654-.212 1.592 1.592 0 0 0-.244.023 1.891 1.891 0 0 0-.327.088 1.348 1.348 0 0 0-.277.129l.216.479a1.241 1.241 0 0 1 .253-.115.726.726 0 0 1 .245-.043c.184 0 .26.083.26.248v.077c-.222.015-.377.03-.457.045a.851.851 0 0 0-.229.056.889.889 0 0 0-.235.134.659.659 0 0 0-.178.215.623.623 0 0 0-.069.3.7.7 0 0 0 .053.28.639.639 0 0 0 .143.21.59.59 0 0 0 .214.134.786.786 0 0 0 .269.045.874.874 0 0 0 .339-.061.776.776 0 0 0 .235-.147l.059.171h.632v-1.4a.823.823 0 0 0-.248-.656zm-.529 1.472c0 .027-.057.049-.1.068a.372.372 0 0 1-.138.027.237.237 0 0 1-.17-.056.185.185 0 0 1-.059-.143.274.274 0 0 1 .055-.173.257.257 0 0 1 .169-.1c.021 0 .135-.014.246-.032z"
                transform="translate(-422.657 -175.525)"
              />
              <path
                id="Path_33105"
                data-name="Path 33105"
                d="M455.275 177.651a.525.525 0 0 0-.226-.255 1.793 1.793 0 0 0-.515-.21 1.93 1.93 0 0 1-.212-.059.416.416 0 0 1-.136-.07.122.122 0 0 1-.045-.1.1.1 0 0 1 .084-.109.705.705 0 0 1 .187-.023.915.915 0 0 1 .312.068 1.582 1.582 0 0 1 .316.158l.226-.479a1.844 1.844 0 0 0-.354-.178 1.5 1.5 0 0 0-.531-.079 1.548 1.548 0 0 0-.327.036.972.972 0 0 0-.309.122.732.732 0 0 0-.231.224.534.534 0 0 0-.081.327.709.709 0 0 0 .048.273.5.5 0 0 0 .149.2 1.012 1.012 0 0 0 .257.146 2.814 2.814 0 0 0 .373.118c.228.057.343.125.343.2a.108.108 0 0 1-.077.113.538.538 0 0 1-.181.027 1.187 1.187 0 0 1-.4-.072 1.113 1.113 0 0 1-.185-.084 1.324 1.324 0 0 1-.144-.1l-.23.515a.884.884 0 0 0 .133.084 1.3 1.3 0 0 0 .212.088 2.032 2.032 0 0 0 .28.067 1.933 1.933 0 0 0 .332.027 1.759 1.759 0 0 0 .434-.054.793.793 0 0 0 .37-.213.623.623 0 0 0 .171-.46.816.816 0 0 0-.043-.248z"
                transform="translate(-429.449 -175.523)"
              />
              <path
                id="Path_33106"
                data-name="Path 33106"
                d="M464.456 174.315a.747.747 0 0 0-.594-.226.909.909 0 0 0-.325.052 1.01 1.01 0 0 0-.23.119v-.916h-.755v3.016h.755v-1.544a.813.813 0 0 1 .144-.094.384.384 0 0 1 .185-.045.235.235 0 0 1 .19.088.438.438 0 0 1 .072.278v1.318h.755v-1.408a.943.943 0 0 0-.197-.638z"
                transform="translate(-436.316 -173.295)"
              />
            </g>
          </g>
        </g>
      </svg>
      <img src="/assets/images/dollar.png" width={"12%"} alt="dollar" />
    </div>
  );
}

export default MomoLogo;
