import React from "react";
import { Link } from "react-router-dom";

function RatingAndLocation() {
  return (
    <div className="d-flex flex-row align-items-center">
      <p
        style={{ textTransform: "capitalize" }}
        className="text-left text-left1 shop-card-text-desc loc-color mt-3 ms-2"
      >
        <span>
          <img
            src="/assets/images/location.png"
            width={"8%"}
            alt="location-logo"
          />
        </span>{" "}
        West Jafrabad, Dhanmondi
      </p>
      <hr
        style={{
          border: "none",
          borderLeft: "2px solid black",
          height: "15px",
          margin: "0",
          padding: "0",
        }}
      />
      <p
        style={{ textTransform: "capitalize" }}
        className="text-left text-left1 shop-card-text-desc loc-color mt-3"
      >
        <span>
          <img
            src="/assets/images/star.png"
            width={"35%"}
            alt="location-logo"
          />
        </span>{" "}
        5.0
      </p>
     
      <Link to={'/reviews'}>(9 Verified Rating)</Link>
    </div>
  );
}

export default RatingAndLocation;
