import React from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from "./components/strings/routes";
import endpoints from "./components/strings/endpoints";
import ProductReview from "./productReviews/ProductReview";
import AddRating from "./productReviews/AddRating";
import CategoriesPage from "./newUi/pages/categoriesPage/CategoriesPage";
import SideBar from "./partials/SideBar";
import ShopsPage from "./newUi/pages/shopsPage/ShopsPage";
import ShopDetailsPage from "./newUi/pages/shopDetailsPage/ShopDetailsPage";
import AllMerchants from "./newUi/pages/categoriesPage/partials/AllMerchants";
import ProductDetailsPage from "./newUi/pages/productdetails/ProductDetailsPage";
import Cart from "./newUi/Cart/Cart";
import { CartContextProvider } from "./newUi/context/CartContext";
import Checkout from "./newUi/checkout/Checkout";
import OtpVerification from "./newUi/checkout/OtpVerification";
import { CheckoutContextProvider } from "./newUi/context/CheckoutContext";
import PaymentOptions from "./newUi/checkout/PaymentOptions";
import HubtelPortal from "./newUi/checkout/options/HubtelPortal";
import GiftCard from "./newUi/checkout/options/GiftCard";
import Orders from "./newUi/checkout/Orders";
import DeliveryAddress from "./newUi/checkout/DeliveryAddress";
import NewMap from "./newUi/checkout/NewMap";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Da-Secret"] = "pCweb";
if (localStorage.getItem("uuid")) {
  axios.defaults.headers.common["X-Guest-Token"] = localStorage.getItem("uuid");
} else {
  localStorage.setItem("uuid", uuidv4());
  // localStorage.setItem("uuid", Math.random().toString(36).slice(2, 5));
  axios.defaults.headers.common["X-Guest-Token"] = localStorage.getItem("uuid");
}

// axios.defaults.baseURL = endpoints.DEV_BASE_URL;
axios.defaults.baseURL = endpoints.BASE_URL;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

function App() {
  return (
    <Router>
      <CartContextProvider>
        <CheckoutContextProvider>
          <div className="App" id="outer-container">
            <SideBar
              pageWrapId={"page-wrap"}
              outerContainerId={"outer-container"}
            />
            <div id="page-wrap">
              <Routes>
                <Route path="/address" element={<DeliveryAddress />} />
                <Route
                  path={routes.INITIAL_ROUTE}
                  element={<CategoriesPage />}
                />
                <Route path={routes.ALL_SHOPS} element={<AllMerchants />} />
                <Route path={routes.SHOPS_PAGE} element={<ShopsPage />} />
                <Route
                  path={routes.SHOP_DETAILS_PAGE}
                  element={<ShopDetailsPage />}
                />
                <Route
                  path={routes.PRODUCT_DETAILS_PAGE}
                  element={<ProductDetailsPage />}
                />
                <Route
                  path={routes.PRODUCT_REVIEW}
                  element={<ProductReview />}
                />
                <Route path={routes.ADD_RATING} element={<AddRating />} />
                <Route path={routes.CART} element={<Cart />} />
                <Route path={routes.ORDERS} element={<Orders />} />
                <Route path={routes.CHECKOUT} element={<Checkout />} />
                <Route
                  path={routes.OTP_VALIDATION}
                  element={<OtpVerification />}
                />
                <Route
                  path={routes.PAYMENT_OPTIONS}
                  element={<PaymentOptions />}
                />
                <Route path={routes.HUBTEL_PORTAL} element={<HubtelPortal />} />
                <Route path={routes.GIFT_CARD_PAYMENT} element={<GiftCard />} />
              </Routes>
            </div>
          </div>
        </CheckoutContextProvider>
      </CartContextProvider>
    </Router>
  );
}

export default App;
