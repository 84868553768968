import React from 'react'
import "./sidebar.css";

const Separator=({text})=>{
    return(
        <div className="separator">
            <div className="secondary-text text-nowrap text-capitalize">{text}</div>
        </div>
    )
}

export default Separator
