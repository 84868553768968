import React from "react";
import Spinners from "./helpers/Spinners";

const Button = ({buttonText,isloading,callback,cssClasses}) => {
  return (
    <button className={cssClasses} type={!isloading ? "submit" : "button"} Í data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" onClick={callback}>
      {!isloading ? buttonText : <>{Spinners.rotatingLines} Please Wait...</>}
    </button>
  );
};

Button.defaultProps={
    callback:()=>{}
}

export default Button;