import ProductCard from "./ProductCard";
import Slider from "react-slick";
import { useEffect, useState, React } from "react";
import axios from "axios";
import { Skeleton } from "@mui/material";

const BestDeals = () => {
  const [shops, setShops] = useState([]);

  const settings = {
    infinite: true,
    autoplay: true,
    variableWidth: true,
    speed: 600,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          // slidesToShow: 3,
          // slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          // slidesToShow: 2,
          // slidesToScroll: 2,
          variableWidth: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: false,
          dots: false,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  useEffect(() => {
    axios
      .get("/prime.sika/v1/markets-places/list?limit=200", {
        params: { is_feature: true },
      })
      .then((res) => {
        if (res.data.success === true && res.data.status === 200) {
          setShops(res?.data?.data?.shops);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  return (
    <>
      <div className="row">
        <div className="shop-text-header mt-4">
          <h2>Best Deals</h2>
        </div>

        <section className="slider variable">
          <Slider {...settings}>
            {shops?.length > 0
              ? shops?.map((shop, index) => {
                  return shop?.latest_products?.map((product, index) => {
                    return (
                      <div className="col-md-3 col-4" key={index}>
                        <ProductCard
                          id={product.code}
                          image={product?.pictures[0]}
                          title={product?.name}
                          description={product?.description}
                          client_location={shop.location || "N/A"}
                          amount={`${product?.price}`}
                        />
                      </div>
                    );
                  });
                })
              : Array(5)
                  .fill("")
                  .map((_, index) => (
                    <div className=" mx-2 mt-3" key={index}>
                      <div className="card card-h-1 me-2">
                        <div className="card-img">
                          <Skeleton
                            variant="rectangular"
                            width="11vw"
                            height="7vw"
                          />
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "0.89rem" }}
                          />
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "0.89rem" }}
                            className="mt-2"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
          </Slider>
        </section>
      </div>
    </>
  );
};

export default BestDeals;
