import "react-phone-number-input/style.css";
import { useNavigate, useParams } from "react-router-dom";
import useGetFetch from "../../../components/customHooks/useGetFetch";
import ProductImages from "./components/partials/ProductImages";
import RatingAndLocation from "./components/partials/RatingAndLocation";
import Footer from "../../../partials/Footer";
import Button from "../../../components/Button";
import MerchantDetails from "./components/partials/MerchantDetails";
import RelatedProducts from "./components/RelatedProducts";
import { useState } from "react";
import routes from "../../../components/strings/routes";
import Nav from "../../../partials/Nav";
import { useCartContext } from "../../context/CartContext";
import ProductReview from "../../../productReviews/ProductReview";
import BestDeals from "../shopDetailsPage/components/BestDeals";

const ProductDetailsPage = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const { data: product } = useGetFetch(
    `/prime.sika/v1/markets-places/products/${code}/details`
  );

  const { addToCart, updateCartItem } = useCartContext();

  const [isProcessing, setProcessing] = useState(false);
  const [itemInCart, setItemInCart] = useState(true);

  const [quantity, setQuantity] = useState(1);

  const handleDecrease = () => {
    if (quantity > 0) {
      setQuantity((prevQuantity) => prevQuantity - 1);
      updateCartItem(product?.product?.id, quantity - 1);
    }
  };
  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
    updateCartItem(product?.product?.id, quantity + 1);
  };

  const handleAddToCart = (e) => {
    e.preventDefault();
    setProcessing(true); //Initiates Processing
    setQuantity(1);
    addToCart(code);

    setItemInCart(true); //Adds Item to Cart
    setProcessing(false); //Terminates Processing
  };

  return (
    <>
      <Nav />
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-5">
            <div className="row">
              <ProductImages images={product?.product?.pictures} />
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-5 mt-2">
            <div className="container">
              <div className="d-flex flex-column">
                <div className="p-0 shop-text-header2">
                  <h2 className="text-left text-capitalize">
                    {product?.product?.name}
                  </h2>
                </div>

                <div className="shop-card-text-desc mt-2">
                  <span className="total-amount">
                    GH¢ {product?.product?.price?.toFixed(2)}
                  </span>
                </div>

                <RatingAndLocation />
              </div>
            </div>

            <div className="mt-2">
              <p className="sub-text text-left">
                {product?.product?.description}
                <br />
                {product?.product?.size && (
                  <span>
                    <b>Size: </b>
                    {product?.product?.size}
                  </span>
                )}
                <br />
                {product?.product?.colors?.length > 0 && (
                  <span>
                    <b>Colors: </b>
                    {product?.product?.colors}
                  </span>
                )}
                <br />
                {product?.product?.weight > 0 && (
                  <span>
                    <b>Weight: </b>
                    {product?.product?.weight}
                  </span>
                )}
              </p>
            </div>

            <div className="mt-3">
              {itemInCart ? (
                <div className="quantity-selector">
                  <button
                    onClick={handleDecrease}
                    className={"me-2 btn btn-outline-success"}
                  >
                    -
                  </button>
                  <span>{quantity}</span>
                  <button
                    onClick={handleIncrease}
                    className={"ms-2 btn btn-outline-success"}
                  >
                    +
                  </button>{" "}
                  <p className="mt-2">(Item added to Cart)</p>
                  <p className="mt-3">
                    <Button
                      buttonText={"Proceed to Checkout"}
                      cssClasses={"btn btn-success"}
                      isloading={isProcessing}
                      callback={() => navigate(routes.CART)}
                    />
                  </p>
                </div>
              ) : (
                <Button
                  buttonText={"Add to Cart"}
                  cssClasses={"btn btn-success "}
                  isloading={isProcessing}
                  callback={handleAddToCart}
                />
              )}
            </div>
          </div>
        </div>

        <MerchantDetails product={product?.product} />
        {/* <RelatedProducts /> */}
        <BestDeals />
        <ProductReview />
      </div>
      <Footer />
    </>
  );
};

export default ProductDetailsPage;
