import React from 'react'
import { useParams } from 'react-router-dom'
import useGetFetch from '../../../components/customHooks/useGetFetch'
import Footer from '../../../partials/Footer'
import Nav from '../../../partials/Nav'
import Shops from './partials/Shops'
import SubCategories from './partials/SubCategories'

function ShopsPage() {

  const {id}=useParams();
  const {data:category,isloaded}=useGetFetch(`/prime.sika/v1/markets-places/products-types-stores?product_type_ids[]=${id}`);
  

  return (
    <div style={{background:'#E5E5E5'}}>
      <Nav/>
        <SubCategories category={category?.shops[0]?.sub_categories} isloaded={isloaded}/>
        <Shops shops={category?.shops[0]?.shops} isloaded={isloaded}/>
      <Footer/>
    </div>
  )
}

export default ShopsPage
