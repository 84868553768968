import React from "react";
import Separator from "../../../../partials/Separator";
import "../shopdetails.css";

function ShopDetails({ shopDetails }) {
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div style={{ width: "100px", height: "100px" }}>
          <img
            src={shopDetails?.logo_url || "/assets/images/rounded-3.png"}
            className="text-center rounded me-2"
            width={"100%"}
            alt="Shop"
          />
        </div>
        <div className="subtitle2 text-capitalize mt-4">
          {shopDetails?.name}
        </div>
        <div className="success-badge">
          <div className="badge-text text-nowrap">
            <span className="me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6.99344 1.48654L3.66677 2.73988C2.9001 3.02654 2.27344 3.93321 2.27344 4.74654V9.69988C2.27344 10.4865 2.79344 11.5199 3.42677 11.9932L6.29344 14.1332C7.23344 14.8399 8.7801 14.8399 9.7201 14.1332L12.5868 11.9932C13.2201 11.5199 13.7401 10.4865 13.7401 9.69988V4.74654C13.7401 3.92654 13.1134 3.01988 12.3468 2.73321L9.0201 1.48654C8.45344 1.27988 7.54677 1.27988 6.99344 1.48654Z"
                  stroke="#338540"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.0332 7.91345L7.10654 8.98678L9.9732 6.12012"
                  stroke="#338540"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            100% Satisfaction Gurantee
          </div>
        </div>
      </div>
      <hr />
      <Separator text={"About The Merchant"} />
      <div className="Secondary-text ms-4">
        {shopDetails?.description || "N/A"}
      </div>
      <div className="text-faint ms-lg-4 mt-2">Location</div>
      <div className="Secondary-text ms-lg-4">
        {shopDetails?.location?.distance || "N/A"}
      </div>
      <div className="text-faint ms-lg-4 mt-2">Contact Info</div>
      <div className="Secondary-text ms-lg-4">
        {shopDetails?.phone || "N/A"}
      </div>
    </>
  );
}

export default ShopDetails;
