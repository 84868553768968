import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

function OrderDetails({ products }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCancel = () => {
    Swal.fire({
      title: "Question",
      text: "Are you sure to cancel this order?, Action can't be reversed",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#198754",
      cancelButtonColor: "#dc3545",
    }).then((res) => {
      if (res.isConfirmed) {
        toast.success("Order has been cancelled successfully");
        handleClose();
      }
    });
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-outline-success"
        onClick={handleShow}
      >
        <i className="bi bi-plus"></i> View
      </button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div classNameName="table-responsive">
            <table classNameName="datatable table table-stripped table table-hover table-center mb-0">
              <thead>
                <th>Image</th>
                <th>Name</th>
                <th>Quantity</th>
                <th>Cost</th>
                <th>Delivery Status</th>
              </thead>
              <tbody>
                {products?.map((item, index) => (
                  <tr>
                    <td width="30%">
                      <img
                        src={item?.product?.pictures[0]}
                        alt="product"
                        width="70%"
                        style={{ borderRadius: "10px" }}
                      />
                    </td>
                    <td>
                      <Link
                        to={`/product/${item?.product?.code}`}
                        className="text-success no-underline"
                      >
                        {item?.product?.name}
                      </Link>
                    </td>
                    {/* REJECTED
                READY_FOR_PICK_UP
                REQUEST_WAITING */}
                    <td className="text-center">{item?.product?.quantity}</td>
                    <td className="text-nowrap">
                      ₵ {Number(item?.unit_amount).toFixed(2)}
                    </td>
                    <td>
                      <small>REQUEST_WAITING</small>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="row">
            <div className="d-flex justify-content-end mt-2">
              <Button
                cssClasses={"btn btn-danger"}
                callback={handleCancel}
                buttonText={"Cancel Order"}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="col-md-12">
            <div className="card rounded-2">
              <div className="card-header">
                <div className="d-flex flex-row justify-content-between shop-text-header2">
                  <h2 className="my-auto">Delivery Details</h2>
                </div>
              </div>

              <div
                className="card-body rounded-2 card-table"
                style={{ height: "230px" }}
              >
                <div className="row">
                  <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <div className="mb-3">
                      <img
                        src="/assets/images/subscribe.png"
                        alt="drlivery"
                        width={"80%"}
                        className="text-center"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="table-responsive mt-5">
                      <table className="datatable table table-stripped table table-hover table-center mb-0">
                        <tbody>
                          <tr>
                            <td>
                              <span className="text-sm">Customer:</span>
                            </td>
                            <td>
                              {products?.[0]?.delivery_address?.full_name}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="text-sm">Contact:</span>
                            </td>
                            <td>
                              {products?.[0]?.delivery_address?.phone_number}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              Delivery Address: <br />
                              <b>
                                {
                                  products?.[0]?.delivery_address
                                    ?.residential_address_name
                                }
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      {/* <div className="d-flex justify-content-center">
                        <button className="btn btn-outline-success form-control mt-3">
                          Update Details
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default OrderDetails;
