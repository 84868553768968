import React from "react";
import Footer from "../../../partials/Footer";
import Nav from "../../../partials/Nav";
import Categories from "./partials/Categories";
import Merchants from "./partials/Merchants";

function CategoriesPage() {

  return (
    <div style={{ background: "#E5E5E5" }}>
        <Nav/>
        <Merchants />
        <Categories />
        <Footer />
    </div>
  );
}

export default CategoriesPage;
