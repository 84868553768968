import React, { useState } from "react";
import { useCartContext } from "../../context/CartContext";

function QuantitySelector({ value, id }) {
  const [quantity, setQuantity] = useState(value);
  const { updateCartItem } = useCartContext();

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
      updateCartItem(id, quantity - 1);
    }
  };
  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
    updateCartItem(id, quantity + 1);
  };

  return (
    <div className="quantity-selector">
      <button
        onClick={handleDecrease}
        className={"me-2 btn btn-outline-success"}
      >
        -
      </button>
      <span>{quantity}</span>
      <button
        onClick={handleIncrease}
        className={"ms-2 btn btn-outline-success"}
      >
        +
      </button>{" "}
    </div>
  );
}

export default QuantitySelector;
