import React from "react";
import Nav from "../../partials/Nav";
import Footer from "../../partials/Footer";
import UserVerification from "./UserVerification";

function Checkout() {
  return (
    <>
      <Nav />
      <div className="container">
      <div className="row mt-5">
        <div className="col-md-2"></div>
        <div className="col-md-8">
         <UserVerification/>
        </div>
        
      </div>
    </div>
      <Footer />
    </>
  );
}

export default Checkout;
