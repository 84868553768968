import React from "react";
import { useParams } from "react-router-dom";
import useGetFetch from "../../../components/customHooks/useGetFetch";
import Footer from "../../../partials/Footer";
import Nav from "../../../partials/Nav";
import Separator from "../../../partials/Separator";
import NavItem from "./partials/NavItem";
import Products from "./partials/Products";
import ShopDetails from "./partials/ShopDetails";
import "./shopdetails.css";

function ShopDetailsPage() {
  const { id } = useParams();
  const { data } = useGetFetch(
    `/prime.sika/v1/markets-places/${id}/shop-list-products`
  );

  return (
    <div style={{ background: "#F2EFEF" }}>
      <Nav />
      <div className="container-fluid ms-0 ms-lg-5 ms-md-5 mt-4">
        <div className="row">
          <div className="col-md-2 container-h  position-relative d-none d-md-block">
            <div className="position-fixed">
              <ShopDetails shopDetails={data?.shop_details} />
              <NavItem />
              <Separator text={"Categories"} />
            </div>
            {/* <Categories /> */}
          </div>
          {/* <div className="col-md-1"></div> */}
          <div className="col-md-9">
            <Products
              shopProducts={data?.shop_products}
              latestProducts={data?.shop_details?.latest_products}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ShopDetailsPage;
