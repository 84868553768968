import React, { useEffect, useState } from "react";
import Nav from "../../partials/Nav";
import Footer from "../../partials/Footer";
import { useNavigate } from "react-router-dom";
import routes from "../../components/strings/routes";
import MomoLogo from "./partials/MomoLogo";
import { useCartContext } from "../context/CartContext";
import { useCheckoutContext } from "../context/CheckoutContext";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

function PaymentOptions() {
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [cart, setCart] = useState();
  const { getCartItems } = useCartContext();
  const { hubtelPayment, getPrimeWalletAmount, walletPayment } =
    useCheckoutContext();
  const [processing, setProcessing] = useState(false);
  const [primeWallet, setPrimeWallet] = useState({});

  useEffect(() => {
    async function loadCart() {
      try {
        const response = await getCartItems();
        setCart(response);
        const walletAccount = await getPrimeWalletAmount();

        if (walletAccount.status === 200) {
          setPrimeWallet(walletAccount?.data?.account);
        }
      } catch (error) {
        console.log(error);
      }
    }
    loadCart();
  }, []);
  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handlePaymentProcessing = async (e) => {
    e.preventDefault();
    setProcessing(true);
    !localStorage.getItem("cart_id") &&
      localStorage.setItem("cart_id", cart?.shop_cart?.shop_cart_id);

    if (paymentMethod === "") {
      window.alert("Please Select Payment Method to Proceed");
    } else if (paymentMethod === "mobile_money_or_bank_payment") {
      const response = await hubtelPayment(
        cart?.shop_cart?.shop_cart_id || localStorage.getItem("cart_id")
      );
      if (response?.status === 400) {
        window.alert(response?.data?.error_msg);
      } else {
        window.location.replace(response);
      }
    } else if (paymentMethod === "prime_gift_card") {
      navigate(routes.GIFT_CARD_PAYMENT);
    } else if (paymentMethod === "prime_wallet_card_payment") {
      const response = await walletPayment(
        cart?.shop_cart?.shop_cart_id || localStorage.getItem("cart_id"),
        primeWallet?.id
      );
      if (response?.status === 400) {
        window.alert(response?.data?.error_msg);
      }
      if (response?.status === 201) {
        toast.success("Payment has been completed successfully");
        navigate(routes.ORDERS);
      }
    }
    setProcessing(false);
  };

  return (
    <>
      <Nav />
      <div className="container">
        <div className="row mt-4">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <div className="card rounded">
              <div className="card-header shop-card-text1">
                <h4>Select Payment Option</h4>
              </div>
              <div className="card-body">
                <form onSubmit={handlePaymentProcessing}>
                  <div className="mb-3">
                    <PaymentMethod
                      paymentMethod={paymentMethod}
                      handlePaymentMethodChange={handlePaymentMethodChange}
                      name={"mobile_money_or_bank_payment"}
                      title={"Mobile Money or Bank Card"}
                      desc=" This options allows you to make payment using your mobile money
                    wallet(MTN, AirtelTigo, VodaCash) or Bank Card (Visa Card,Master
                    Card)"
                      logo={<MomoLogo />}
                    />
                    <hr />
                    <PaymentMethod
                      paymentMethod={paymentMethod}
                      handlePaymentMethodChange={handlePaymentMethodChange}
                      name={"prime_wallet_card_payment"}
                      title={`Prime Wallet (${primeWallet?.currency || "GHS"} ${
                        primeWallet?.available_balance || 0.0
                      })`}
                      desc={` This options allows you to make payment using the balance you have in your Prime wallet.`}
                      logo={
                        <div className="d-flex justify-content-end">
                          <img
                            src="/assets/images/prime_wallet.png"
                            width={"15%"}
                            alt="dollar"
                          />
                        </div>
                      }
                    />
                    <hr />
                    <PaymentMethod
                      paymentMethod={paymentMethod}
                      handlePaymentMethodChange={handlePaymentMethodChange}
                      name={"prime_gift_card"}
                      title={"Prime Gift Card Redemption"}
                      desc={` This option allows you to redeem your gift card by making payment for products you've added to your cart`}
                      logo={
                        <div className="d-flex justify-content-end">
                          <img
                            src="/assets/images/new3.png"
                            width={"20%"}
                            alt="dollar"
                          />
                        </div>
                      }
                    />

                    <hr />
                    <div className="row text-right px-2">
                      {!processing ? (
                        <button
                          type="submit"
                          className="btn  btn-outline-success"
                        >
                          Proceed to Payment
                        </button>
                      ) : (
                        <button disabled className="btn  btn-outline-success">
                          Processing...
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

const PaymentMethod = ({
  paymentMethod,
  handlePaymentMethodChange,
  name,
  title,
  desc,
  logo,
}) => {
  return (
    <>
      <div className="form-check">
        <input
          className="form-check-input mt-2"
          type="radio"
          name={name}
          id={name}
          value={name}
          checked={paymentMethod === name}
          onChange={handlePaymentMethodChange}
        />
        <div className="d-flex flex-column  flex-row-reverse gap-5">
          {logo}
          <div
            className="form-check-label subtitle2 text-nowrap ellipsis-text"
            htmlFor={name}
          >
            {title}
          </div>
        </div>
      </div>
      {paymentMethod === name && (
        <div className="mx-4">
          <small className="form-text text-muted shop-text-desc2">{desc}</small>
        </div>
      )}
    </>
  );
};

export default PaymentOptions;
