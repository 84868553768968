import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import { useCheckoutContext } from "../../context/CheckoutContext";
import axios from "axios";
import routes from "../../../components/strings/routes";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

function GiftCard() {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isCardVerified, setCardVerified] = useState(false);
  const { primeCardPayment } = useCheckoutContext();
  const [card, setCard] = useState();
  const [isCardLoading, setCardLoading] = useState(false);
  const [cardLoaded, setCardLoaded] = useState(false);
  const [isPaymentProcessing, setPaymentProcessing] = useState(false);

  const handleChangeCard = () => {
    setCard("");
    setCode("");
    setCardLoaded(false);
    setPaymentProcessing(false);
  };

  const handlePaymentProcessing = async (e) => {
    e.preventDefault();
    setPaymentProcessing(true);
    const response = await primeCardPayment(
      Number(localStorage.getItem("cart_id")),
      code.replace(/\s/g, "")
    );
    if (response?.status === 201) {
      setPaymentProcessing(false);
      navigate(routes.ORDERS);
    } else {
      if (response?.status === 400) {
        toast.error(
          response?.data?.error_msg ||
            "An error occured while processing payment, please try again later"
        );
      } else {
        toast.error(
          "An error occured while processing payment, please try again later"
        );
      }
      setPaymentProcessing(false);
      setCardLoaded(false);
    }
  };

  const handleChange = (event) => {
    setCard({});
    setCardLoaded(false);
    const inputCode = event.target.value.replace(/\s/g, ""); // Remove spaces
    const formattedCode = inputCode
      .replace(/(.{4})/g, "$1 ") // Add space every 4 characters
      .trim(); // Remove extra space at the end
    setCode(formattedCode);
    setErrorMessage("");
  };

  const handleValidation = () => {
    const codeWithoutSpaces = code.replace(/\s/g, ""); // Remove spaces

    // const item = data?.card_accounts?.find(
    //   (item) => item?.puchase_code === codeWithoutSpaces
    // );
    setCardLoading(true);
    axios
      .get(`prime.sika/v1/accounts/merchant/${codeWithoutSpaces}`)
      .then((res) => {
        if (res?.status === 200) {
          setCard(res?.data?.data?.account_log);
          setCardLoaded(true);
          // setCardVerified(true);
        }
        if (res?.status === 400) {
          window.alert(res?.data?.error_msg);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 400) {
          window.alert(err?.response?.data?.error_msg);
        }
        console.log(err);
      })
      .finally((_) => setCardLoading(false));

    // if (item) {
    //   setCard(item);
    //   setCardLoaded(true);
    // } else {
    //   setErrorMessage(
    //     "Invalid Card Code Entered,Please Re-check and Try Again"
    //   );
    // }
  };

  useEffect(() => {
    const codeWithoutSpaces = code.replace(/\s/g, "");
    if (codeWithoutSpaces.length === 16) {
      setCardLoading(true);
      handleValidation();
    }
  }, [code]);

  return (
    <>
      <div className="container">
        <div className="row mt-4">
          <div className="text-center">
            <img
              src="/assets/images/logo.png"
              alt="logo"
              width={"10%"}
              className="img-fluid"
            />
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <div className="card rounded" style={{ height: "500px" }}>
              <div className="card-header shop-card-text1 d-flex flex-row align-items-center">
                <img
                  src="/assets/images/arrow-left.png"
                  alt="logo"
                  width={"5%"}
                  onClick={() => navigate(-1)}
                  style={{ cursor: "pointer" }}
                  className="img-fluid"
                />
                <h4 className="mt-2">Payment with Prime Gift Card</h4>
              </div>
              <div
                className="d-flex flex-column align-items-center justify-content-center text-center"
                style={{ height: "100%" }}
              >
                {isCardVerified === false && (
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="d-flex justify-content-center">
                          <img
                            src={card?.card?.image || "/assets/images/new3.png"}
                            width={cardLoaded ? "80%" : "80%"}
                            className={`rounded-3 ${
                              cardLoaded ? "mt-4" : "mt-2"
                            }`}
                            alt="dollar"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h2 className={`subtitle ${!cardLoaded && "mt-4"}`}>
                          Enter 16-Digits Gift Card PIN:
                        </h2>
                        <div className="row">
                          <div className="pe-4">
                            <input
                              type="text"
                              value={code}
                              maxLength={19} // Maximum of 16 characters and 3 spaces
                              onChange={handleChange}
                              className="form-control text-center"
                              disabled={cardLoaded}
                            />
                            <></>
                          </div>
                          {isCardLoading && (
                            <p className="text-sucess">
                              Verification in Process...
                            </p>
                          )}
                          <p className="text-danger">{errorMessage}</p>
                        </div>
                        {cardLoaded && (
                          <div className="card-body p-0">
                            Gift Card Balance:
                            <h4 className="p-0">
                              GHS {Number(card?.current_balance).toFixed(2)}
                            </h4>
                            <br />
                            Total Cart Amount: GHS{" "}
                            {localStorage.getItem("total_amount")}
                            <br />
                            {/* <Button
                        buttonText={"Click Here to Top-Up (GHS 10.00)"}
                        cssClasses={"btn btn-success mt-2"}
                        isloading={processing}
                      />
                      <br /> */}
                            <Button
                              buttonText={"Click to Confirm Payment"}
                              cssClasses={"btn btn-success mt-2 mb-2"}
                              callback={handlePaymentProcessing}
                              isloading={isPaymentProcessing}
                            />
                            <br />
                            <Button
                              buttonText={"Use Different Card"}
                              cssClasses={"btn btn-info text-white mt-2 mb-2"}
                              callback={handleChangeCard}
                              isloading={isPaymentProcessing}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GiftCard;
