import React, { useState } from "react";
import Button from "../../../components/Button";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import axios from "axios";
import UserVerification from "../../checkout/UserVerification";
import OtpVerification from "../../checkout/OtpVerification";
import toast from "react-hot-toast";
import PinVerification from "../../checkout/PinVerification";

function BillingDetails({ cart }) {
  const [phone, setPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showOtpVerification, setShowOtpVerification] = useState(false);
  const [isVerifyingPhoneNumber, setVerifyingPhoneNumber] = useState(false);
  const [showPinVerification, setShowPinVerification] = useState(false);

  const verifyPhoneNumber = (value) => {
    localStorage.setItem("phone", value);
    localStorage.setItem("cart_id", cart?.shop_cart?.shop_cart_id);
    localStorage.setItem("total_amount", cart?.shop_cart?.total_amount);

    axios
      .get("prime.sika/v1/users/verify-id", { params: { user_id: value } })
      .then((res) => {
        console.log(res?.data);
        toast.success("Phone Number Verification Successful");
        setShowPinVerification(true);
      })
      .catch((err) => {
        console.log(err?.response);
        if (err?.response?.status === 400) {
          toast.success("Enter 4 Digit OTP Code", { duration: 7000 });
          handleSubmit();
        } else {
          toast.error("Error Verifying Phone Number");
        }
      })
      .finally(() => {
        setVerifyingPhoneNumber(false);
      });
  };

  const handlePhoneChange = (value) => {
    setVerifyingPhoneNumber(true);
    try {
      setPhone(value);
      if (isValidPhoneNumber(value)) {
        // If the phone number is valid, make the API call
        verifyPhoneNumber(value);
      }
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    if (!isValidPhoneNumber(localStorage.getItem("phone"))) {
      window.alert("Please enter a valid phone number");
      return;
    }

    // setScreen('otp');
    // try {
    //   const url = '/prime.sika/v1/customers/guest-otp';
    //   const requestOptions = {
    //     method: 'POST',
    //     body:JSON.stringify({
    //       telephone:phone
    //     }),
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Da-secret': 'pCweb',
    //       'X-Guest-Token':localStorage.getItem("uuid")
    //       // Add any additional headers if required
    //     },
    //     // Add any payload (body) data if required
    //     // body: JSON.stringify({ key: value }),
    //   };

    //   const response = await fetch(url, requestOptions);
    //   const data = await response;
    //   console.log(data);
    //   // Assuming the response contains a "message" field
    //   // setResponseMessage(data.message);
    // } catch (error) {
    //   console.error('Error:', error);
    // }

    axios
      .post(
        "/prime.sika/v1/customers/guest-otp",
        { telephone: localStorage.getItem("phone") },
        {
          headers: {
            "Da-secret": "pCweb",
            "X-Guest-Token": localStorage.getItem("uuid"),
          },
        }
      )
      .then((res) => {
        console.log(res?.data);
        setShowOtpVerification(true);
        setIsLoading(true);
      })
      .catch((err) => {
        console.log(err.response);
        toast.error("OTP Failed");
      })
      .finally(() => setIsLoading(false));

    // navigate(routes.PAYMENT_OPTIONS);
  };

  return (
    <div className="card rounded-2">
      <div className="card-header">
        <div className="d-flex flex-row justify-content-between shop-text-header2">
          <h2 className="my-auto">Billing & Delivery Info</h2>
        </div>
      </div>

      <div className="card-body rounded-2 card-table">
        <div className="table-responsive">
          <table className="datatable table table-stripped table table-hover table-center mb-0">
            <tbody>
              <tr>
                <td>Item Total</td>
                <td>
                  <b>¢ {cart?.shop_cart?.net_amount || 0}</b>
                </td>
              </tr>
              <tr>
                <td>Charges</td>
                <td className="shop-card-text1">
                  ¢ {cart?.shop_cart?.fees || 0}
                </td>
              </tr>
              <tr>
                <td>Total</td>
                <td>
                  <span className="subtitle2">
                    ¢ {cart?.shop_cart?.total_amount || 0}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div className="text-center">
          {!showPinVerification && (
            <>
              {!showDetails && (
                <Button
                  cssClasses={"btn btn-outline-success"}
                  buttonText={"Checkout"}
                  callback={() => setShowDetails(true)}
                />
              )}
            </>
          )}
        </div>
        {showDetails && (
          <div className="collapse" id="collapseExample">
            {!showOtpVerification ? (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12 text-left mb-2">
                    <label htmlFor="phone" className=" text-left">
                      Phone Number
                    </label>

                    <PhoneInput
                      country="GH"
                      defaultCountry="GH"
                      // countries={['GH']}
                      value={phone}
                      onChange={handlePhoneChange}
                      className="form-control custom-phone-input card-quantity-textbox d-flex "
                      placeholder="Please Enter your phone number"
                    />
                    <div className="text-danger">
                      {phone
                        ? isValidPhoneNumber(phone)
                          ? ""
                          : "Invalid phone number"
                        : "Phone number required"}
                    </div>
                    <div className="text-success">
                      {phone &&
                      isValidPhoneNumber(phone) &&
                      isVerifyingPhoneNumber === true
                        ? "Phone Verification in progress please wait..."
                        : ""}
                    </div>
                  </div>

                  {/* <div className="col-md-12 text-center mt-3">
                    {!isLoading ? (
                      <button type="submit" className="btn btn-success">
                        Proceed To Payment
                      </button>
                    ) : (
                      <button
                        type="button"
                        disabled
                        className="btn btn-success"
                      >
                        Please Wait..
                      </button>
                    )}
                  </div> */}
                  {showPinVerification && <PinVerification />}
                </div>
              </form>
            ) : (
              <OtpVerification />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default BillingDetails;
