import ProductCard from "./ProductCard";
import { React } from "react";
import Slider from "react-slick";

const NewProducts = ({ latestProducts }) => {
  const settings = {
    infinite: true,
    autoplay: true,
    variableWidth: true,
    arrows: false,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          // slidesToShow: 3,
          // slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          // slidesToShow: 2,
          // slidesToScroll: 2,
          variableWidth: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: false,
          dots: false,
        },
      },
    ],
  };

  if (latestProducts?.length > 0) {
    return (
      <>
        <div className="">
          <div className="shop-text-header mt-4">
            <h2>New on Prime</h2>
          </div>
        </div>

        <Slider {...settings}>
          {latestProducts.map((item, index) => (
            <div className="col-md-2 col-lg-2 col-sm-6 mt-2" key={index}>
              <ProductCard
                id={item?.code}
                image={item?.pictures?.[0]}
                title={item?.name}
                description={item?.description}
                client_location={item?.client_location}
                amount={`${item?.price}`}
              />
            </div>
          ))}
        </Slider>
      </>
    );
  }
};

export default NewProducts;
