import Footer from "../../partials/Footer";
import Nav from "../../partials/Nav";
import useGetFetch from "../../components/customHooks/useGetFetch";
import OrderDetails from "./OrderDetails";

const Orders = () => {
  // const { data } = useGetFetch("/prime.sika/v1/markets-places/orders");
  const { data } = useGetFetch("/prime.sika/v1/shop-payments/customer/list");
  console.log(
    data?.shop_payments?.[0]?.orders?.[0]?.delivery_address?.full_name
  );
  var renderedComponent = "";
  if (!data?.shop_payments) {
    renderedComponent = (
      <div className="d-flex justify-content-center align-items-center">
        <h1>Loading... Please Wait...</h1>
      </div>
    );
  } else if (data && data?.shop_payments?.length > 0) {
    renderedComponent = (
      <table className="datatable table table-stripped table table-hover table-center mb-0">
        <thead>
          <th>My Orders</th>
          <th>Order ID</th>
          <th className="text-center">Status</th>
          <th>Quantity</th>
          <th>Cost</th>
          <th>Action</th>
        </thead>
        <tbody>
          {data?.shop_payments?.map((item, index) => (
            <tr key={index}>
              <td width="30%">
                <img
                  src={
                    item?.product?.pictures[0] ||
                    "https://market-products-files.s3.us-east-2.amazonaws.com/mobile/20230505101435-559-mm.webp"
                  }
                  alt="product"
                  width="70%"
                  style={{ borderRadius: "10px" }}
                />
              </td>
              <td>{item?.reference}</td>
              <td
                className={`text-center ${
                  item?.status === "REJECTED" && "text-danger"
                } ${item?.status === "READY_FOR_PICK_UP" && "text-success"}
                ${item?.status === "REQUEST_WAITING" && "text-warning"}`}
              >
                <span>{item?.status?.replace(/_/g, " ")}</span>
              </td>
              <td>{item?.orders?.length}</td>
              <td>GHS {Number(item?.amount).toFixed(2)}</td>
              <td>
                <OrderDetails products={item?.orders} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  } else {
    renderedComponent = (
      <div className="d-flex justify-content-center align-items-center">
        <h1>Your Have No Orders</h1>
      </div>
    );
  }

  return (
    <>
      <Nav />
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-8">
            <div className=" rounded-2">
              {/* <div className="card-header">
                <div className="d-flex flex-row justify-content-between shop-text-header2">
                  <h2 className="my-auto">My Orders</h2>
                </div>
              </div> */}
              <div className="card-table card-product-h">
                {/* <div className="card-table"> */}
                <div className="card-body booking_card">
                  <div className="table-responsive">{renderedComponent}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card rounded-2">
              <div className="card-header">
                <div className="d-flex flex-row justify-content-between shop-text-header2">
                  <span className="my-auto">Delivery Details</span>
                </div>
              </div>

              <div className="card-body rounded-2 card-table">
                <div className="d-flex justify-content-center mb-3">
                  <img
                    src="/assets/images/subscribe.png"
                    alt="drlivery"
                    width={"60%"}
                    className="text-center"
                  />
                </div>
                <div className="table-responsive mt-5">
                  <table className="datatable table table-stripped table table-hover table-center mb-0">
                    <tbody>
                      <tr>
                        <td>
                          <span className="text-sm">Customer:</span>
                        </td>
                        <td>
                          {
                            data?.shop_payments?.[0]?.orders?.[0]
                              ?.delivery_address?.full_name
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-sm">Contact:</span>
                        </td>
                        <td>
                          {" "}
                          {
                            data?.shop_payments?.[0]?.orders?.[0]
                              ?.delivery_address?.phone_number
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="text-sm">Delivery Address:</span>
                        </td>
                        <td>
                          {
                            data?.shop_payments?.[0]?.orders?.[0]
                              ?.delivery_address?.residential_address_name
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Orders;
