import React from "react";

function MerchantDetails({product}) {
  return (
    <div className="container">
      <div className="row mt-3">
        <div className="subtitle2 text-left text-black">
          <b>Merchant Info</b>
        </div>
        <div className="mt-2">
          <p className="sub-text text-left">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable.
          </p>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-3">
          <div className="subtitle2 text-left text-black">Location</div>
          <p className="sub-text text-left">
            East Legon Accra Central Achimota
          </p>
        </div>
        <div className="col-md-3">
          <div className="subtitle2 text-left text-black">Contact Info</div>
          <p className="sub-text text-left">+233247117448</p>
        </div>
        <div className="col-md-3">
          <div className="subtitle2 text-left text-black">
            Terms and Conditions
          </div>
          <p className="sub-text text-left">+233247117448</p>
        </div>
        <div className="col-md-3">
          <div className="subtitle2 text-left text-black">Return Policy</div>
          <p className="sub-text text-left">{product?.return_policy}</p>
        </div>
      </div>
    </div>
  );
}

export default MerchantDetails;
