import React from "react";
import "../shopdetails.css";

function NavItem() {
  return (
    <div className="mt-3">
      <a className="shop-menu-item shop-nav-active" href="/salads">
        <NavItemComponent
          iconUrl={"/assets/images/new/shop.png"}
          title={"Shop"}
        />
      </a>
      <a className="shop-menu-item" href="/salads">
        <NavItemComponent
          iconUrl={"/assets/images/new/tag-2.png"}
          title={"Deals"}
        />
      </a>
      <a className="shop-menu-item" href="/salads">
        <NavItemComponent
          iconUrl={"/assets/images/new/refresh-2.png"}
          title={"Order Again"}
        />
      </a>
      <a className="shop-menu-item" href="/salads">
        <NavItemComponent
          iconUrl={"/assets/images/new/card.png"}
          title={"Buy E-gift Card"}
        />
      </a>
      <a className="shop-menu-item" href="/salads">
        <NavItemComponent
          iconUrl={"/assets/images/new/shopping-cart.png"}
          title={"Wishlist"}
        />
      </a>
    </div>
  );
}

const NavItemComponent = ({ iconUrl, title }) => {
  return (
    <div className="d-flex flex-row align-items-center gap-2 shop-nav nav-outer">
      <div className="nav-inner">
        <img src="/assets/images/new/nav_yellow.png" alt="nav_yellow" />
      </div>
      <img
        src={iconUrl}
        className="img-fluid mt-2"
        alt="wishlist-icon"
        width={"10%"}
      />
      <span className="nav-text">{title}</span>
    </div>
  );
};

export default NavItem;
