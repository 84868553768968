import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../partials/Footer";

function AddRating() {
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);

  const handleRatingClick = (value) => {
    setRating(value);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="card mt-4">
            <div className="card-header d-flex flex-row">
              <span>
                <img
                  src="/assets/images/arrow-left.png"
                  onClick={() => navigate(-1)}
                  alt="back"
                />
              </span>
              <div className="subtitle2 text-left text-black mt-2">
                <b>Add Merchant Review</b>
              </div>
            </div>
            <div className="card-body">
              <div className="d-flex flex-row">
                <div className="d-flex flex-column">
                  <p className="text-center">Rate this product</p>
                  <div className="rating-box align-items-center justify-content-center p-4">
                    <h3 className="text-center">
                      <b>{rating}/5</b>
                    </h3>
                    <div className="text-center">
                      <div>
                        {[...Array(5)].map((_, index) => (
                          <span
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleRatingClick(index + 1)}
                            className="stars "
                          >
                            {index < rating ? (
                              <span className="checked">★</span>
                            ) : (
                              "☆"
                            )}
                          </span>
                        ))}
                      </div>
                      {rating && <p>{rating} Stars Selected</p>}
                    </div>
                  </div>
                  <p>5 Stars - Excellent<br/>
                  4 Stars - V. Good<br/>
                  3 Stars - Good<br/>
                  2 Stars - poor<br/>
                 1 Star - V. poor</p>
                </div>
                <div className="ms-5">
                  <p>Add Some Review Comment </p>
                  <div className="row">
                    <form>
                        <textarea cols={100} rows={10} className={'form-control'} placeholder={'Enter Review Comment Here'}/>
                        <button className="btn btn-success form-control mt-3">Submit Review</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AddRating;
