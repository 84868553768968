import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import toast from "react-hot-toast";
import Swal from "sweetalert2";

export const CartContext = createContext({
  cart: "",
  setCartItems: () => {},
  addToCart: () => {},
  updateCartItem: () => {},
  deleteFromCart: () => {},
});

export const CartContextProvider = ({ children }) => {
  const [cart, setCart] = useState();
  const [processing, setProcessing] = useState();

  const getCartItems = () => {
    return axios
      .get("/prime.sika/v1/markets-places/current-product-cart")
      .then((res) => {
        setCart(res?.data?.data);
        return res?.data?.data;
      });
  };

  const addToCart = (id) => {
    localStorage.getItem("token") && localStorage.removeItem("token");
    setProcessing(true);
    return axios
      .post("/prime.sika/v1/markets-places/create-shopcart", {
        description: "Marketplace Cart Items",
        items: [
          {
            product_id: id,
            quantity: 1,
          },
        ],
      })
      .then((res) => {
        if (res?.data?.status === 201) {
          setCart(res?.data?.data);
          toast.success("Item has been added to cart", { duration: 8000 });

          return true;
        }
      })
      .finally(() => {
        return true;
      });
  };

  const deleteFromCart = (id) => {
    axios
      .put(
        `/prime.sika/v1/markets-places/remove/${id}?shop_cart_id=${cart?.shop_cart?.shop_cart_id}`
      )
      .then((res) => {
        if (res?.data?.status === 200) {
          setCart(res?.data?.data);
        }
      });
  };

  const updateCartItem = (id, quantity) => {
    axios
      .get("/prime.sika/v1/markets-places/current-product-cart")
      .then((res) => {
        setCart(res?.data?.data);
        if (res?.data?.data?.shop_cart?.shop_cart_id === undefined) {
          addToCart(id);
        } else {
          const data = cart?.shop_cart?.items;

          if (Number(data?.length) > Number(0)) {
            const cart_item = data?.find(
              (item) => Number(item?.product?.id) === Number(id)
            );

            if (cart_item) {
              axios
                .put(
                  `/prime.sika/v1/markets-places/update/${cart_item?.id}?shop_cart_id=${cart?.shop_cart?.shop_cart_id}&quantity=${quantity}`
                )
                .then((res) => {
                  if (res?.data?.status === 200) {
                    setCart(res?.data?.data);
                  }
                });
            }
          }
        }
      });
  };

  const clearCart = () => {
    axios
      .post(
        `/prime.sika/v1/markets-places/clear?shop_cart_id=${cart?.shop_cart?.shop_cart_id}`
      )
      .then((res) => {
        if (res?.data?.status === 201) {
          setCart(res?.data?.data);
        }
      });
  };

  const value = {
    cart,
    setCart,
    addToCart,
    updateCartItem,
    deleteFromCart,
    processing,
    setProcessing,
    getCartItems,
    clearCart,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export default CartContext;

export const useCartContext = () => {
  const {
    cart,
    setCart,
    addToCart,
    updateCartItem,
    deleteFromCart,
    processing,
    getCartItems,
    clearCart,
  } = useContext(CartContext);

  return {
    cart,
    setCart,
    addToCart,
    updateCartItem,
    deleteFromCart,
    processing,
    getCartItems,
    clearCart,
  };
};
