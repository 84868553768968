import { useRef, useEffect, useState } from "react";
import Nav from "../../partials/Nav";
import Footer from "../../partials/Footer";
import  { useCheckoutContext } from "../context/CheckoutContext";
import { useNavigate } from "react-router-dom";
import routes from "../../components/strings/routes";
import PinVerification from "./PinVerification";

const OtpVerification = () => {
  const box1 = useRef("");
  const box2 = useRef("");
  const box3 = useRef("");
  const box4 = useRef("");

  const [isVerifying, setIsVerifying] = useState(false);
  const navigate=useNavigate();

  useEffect(() => {
    box1.current.focus();
    !localStorage.getItem('phone')&& navigate(-1);
  }, [navigate]);

  const [code, setCode] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
    six: "",
  });
  const [verificationCode, setVerificationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { otpValidation } = useCheckoutContext();
  const [showPin,setShowPin]=useState(false);

  const handleValidation = async (e) => {
    e.preventDefault();
    setIsVerifying(true);
    if (verificationCode.length !== 4) {
      setErrorMessage("Invalid Verification Code Entered");
      setIsVerifying(false);
    } else {
      
      const response = await otpValidation(
        localStorage.getItem("phone"),
        verificationCode
      );
        console.log(response);
      if(response?.status===200){
        console.log({'otp_reposen':response});
        navigate(routes.PAYMENT_OPTIONS)
      }

      if(response?.status===201){
        setShowPin(true);
      }
      
      if(response?.success===false && (response?.status!==200||response?.status!==201)){
        setErrorMessage(response?.error_msg);
      }
    }
    setIsVerifying(false);
  };

  // const handleValidation = (e) => {
  //   setIsVerifying(true);
  //   if (verificationCode.length !== 4) {
  //     setErrorMessage("Invalid Verification Code Entered");
  //     setIsVerifying(false);
  //   } else {
  //     axios
  //       .post("/prime.sika/v1/customers/guest-signup", {
  //         telephone: userData.telephone,
  //         user_type: "customer",
  //         otp: verificationCode,
  //       })
  //       .then((res) => {
  //         console.log(res.data);
  //         if (res.data.status === 200 && res.data.success === true) {
  //           localStorage.setItem("token", res.data.data.token);
  //           setScreen("account_created");
  //           makePayment();
  //         } else if (res.data.status === 201) {
  //           setScreen("login");
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         if (
  //           (err.response.data.status === 400 || err.response.data.status === 401) &&
  //           err.response.data.success === false
  //         ) {
  //           setErrorMessage(err.response.data.error_msg);
  //         }
  //         setIsVerifying(false);
  //       });
  //   }
  // };

  useEffect(() => {
    // setVerificationCode(code.one+code.two+code.three+code.four+code.five+code.six)
    setVerificationCode(code.one + code.two + code.three + code.four);
  }, [code]);

  const handleNextFocus = (e) => {
    setErrorMessage("");
    setCode((code) => ({
      ...code,
      [e.target.id]: e.target.value.toString(),
    }));

    if (e.target.id === "one" && e.target.value !== "") {
      box2.current.focus();
    }
    if (e.target.id === "two" && e.target.value !== "") {
      box3.current.focus();
    }
    if (e.target.id === "three" && e.target.value !== "") {
      box4.current.focus();
    }
    // if(e.target.id==="four" && e.target.value!==""){
    //     box5.current.focus()
    // }
    // if(e.target.id==="five" && e.target.value!==""){
    //     box6.current.focus()
    // }
    //code.one+code.two+code.three+code.four+code.five+code.six
  };

  return (
    <>
    {
      !showPin?
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h4 className="text-center">OTP Verification</h4>
              </div>
              <div className="card-body">
                <div className="font-enter text-center">
                  <h4>Please enter the security code recieved via SMS</h4>
                </div>
                <div className="form-group mt-4 d-flex justify-content-center">
                  <input
                    type="text"
                    id="one"
                    className="form-control textbox-size1"
                    min={0}
                    max={9}
                    maxLength={1}
                    ref={box1}
                    value={code.one}
                    onChange={handleNextFocus}
                  />
                  <input
                    type="text"
                    id="two"
                    className="form-control textbox-size1 ms-2"
                    maxLength="1"
                    ref={box2}
                    value={code.two}
                    onChange={handleNextFocus}
                  />
                  <input
                    type="text"
                    id="three"
                    className="form-control textbox-size1 ms-2"
                    maxLength="1"
                    ref={box3}
                    value={code.three}
                    onChange={handleNextFocus}
                  />
                  <input
                    type="text"
                    id="four"
                    className="form-control textbox-size1 ms-2"
                    maxLength="1"
                    ref={box4}
                    value={code.four}
                    onChange={handleNextFocus}
                  />
                  {/* <input
          type="text"
          id="five"
          className="form-control textbox-size1 ms-2"
          maxLength="1"
          ref={box5}
          value={code.five}
          onChange={handleNextFocus}
        />
        <input
          type="text"
          id="six"
          className="form-control textbox-size1 ms-2"
          maxLength="1"
          ref={box6}
          value={code.six}
          onChange={handleNextFocus}
        /> */}
                </div>
                <div className="text-center">
                  <small className="text-danger">{errorMessage}</small>
                </div>

                <div className="mt-4 continue-btn d-flex justify-content-center">
                  {!isVerifying ? (
                    <button
                      className="btn btn-success btn-next rounded-3"
                      onClick={handleValidation}
                    >
                      Verify OTP Code
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-success btn-next"
                      disabled
                    >
                      Please wait...
                    </button>
                  )}
                  {/* <a href="/signup" className="text-success text-resend-color">
          Resend code?
        </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      :<PinVerification/>
    }
    </>
  );
};

export default OtpVerification;
