import { useRef, useState, useEffect } from "react";
import axios from "axios";
import routes from "../../components/strings/routes";
import { useNavigate } from "react-router-dom";
import DeliveryAddress from "./DeliveryAddress";

const PinVerification = ({ userData, makePayment }) => {
  const box1 = useRef("");
  const box2 = useRef("");
  const box3 = useRef("");
  const box4 = useRef("");
  const box5 = useRef("");
  const box6 = useRef("");
  const navigate = useNavigate();
  const [showDeliveryAddress, setShowDeliveryAddress] = useState(false);

  const [isVerifying, setIsVerifying] = useState(false);

  const [code, setCode] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
    six: "",
  });
  const [verificationCode, setVerificationCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [pin, isPin] = useState(true);
  const [password, setPassword] = useState("");

  //   I Will Continue From Here
  const handleValidation = (e) => {
    setIsVerifying(true);
    if (verificationCode.length !== 4) {
      setErrorMessage("Invalid Verification Code Entered");
      setIsVerifying(false);
    } else {
      var data;
      pin
        ? (data = {
            forname: localStorage.getItem("phone"),
            pin: verificationCode,
            user_type: "customer",
          })
        : (data = {
            forname: userData.telephone,
            password: password,
            user_type: "customer",
          });
      localStorage.removeItem("token");
      axios
        .post("/prime.sika/v1/users/login", data)
        .then((res) => {
          if (res.data.status === 200 && res.data.success === true) {
            localStorage.setItem("token", res.data.data.token);
            // navigate(routes.PAYMENT_OPTIONS); ////////////////////////////////////////////
            setShowDeliveryAddress(true);
          }
        })
        .catch((err) => {
          console.log(err.response);
          if (
            err.response.data.status === 401 &&
            err.response.data.success === false &&
            err.response.data.error_msg === "Unauthorized"
          ) {
            setErrorMessage("Invalid Credentials Entered");
          }
          setIsVerifying(false);
        });
    }
  };

  useEffect(() => {
    // setVerificationCode(code.one+code.two+code.three+code.four+code.five+code.six)
    setVerificationCode(code.one + code.two + code.three + code.four);
  }, [code]);

  const handleNextFocus = (e) => {
    setErrorMessage("");
    setCode((code) => ({
      ...code,
      [e.target.id]: e.target.value.toString(),
    }));

    if (e.target.id === "one" && e.target.value !== "") {
      box2.current.focus();
    }
    if (e.target.id === "two" && e.target.value !== "") {
      box3.current.focus();
    }
    if (e.target.id === "three" && e.target.value !== "") {
      box4.current.focus();
    }
    // if(e.target.id==="four" && e.target.value!==""){
    //     box5.current.focus()
    // }
    // if(e.target.id==="five" && e.target.value!==""){
    //     box6.current.focus()
    // }
    //code.one+code.two+code.three+code.four+code.five+code.six
  };

  return (
    <>
      {!showDeliveryAddress ? (
        <>
          <div className="font-enter text-center">
            <h3 className="text-center">Account Verification</h3>
            {pin ? (
              <h4>Please enter your prime pin Code</h4>
            ) : (
              <h4>Please enter your Password</h4>
            )}
          </div>
          {pin ? (
            <div className="form-group mt-4 d-flex justify-content-center">
              <input
                type="text"
                id="one"
                className="form-control textbox-size1"
                min={0}
                max={9}
                maxLength={1}
                ref={box1}
                value={code.one}
                onChange={handleNextFocus}
              />
              <input
                type="text"
                id="two"
                className="form-control textbox-size1 ms-2"
                maxLength="1"
                ref={box2}
                value={code.two}
                onChange={handleNextFocus}
              />
              <input
                type="text"
                id="three"
                className="form-control textbox-size1 ms-2"
                maxLength="1"
                ref={box3}
                value={code.three}
                onChange={handleNextFocus}
              />
              <input
                type="text"
                id="four"
                className="form-control textbox-size1 ms-2"
                maxLength="1"
                ref={box4}
                value={code.four}
                onChange={handleNextFocus}
              />
              {/* <input
                type="text"
                id="five"
                className="form-control textbox-size1 ms-2"
                maxLength="1"
                ref={box5}
                value={code.five}
                onChange={handleNextFocus}
                />
                <input
                type="text"
                id="six"
                className="form-control textbox-size1 ms-2"
                maxLength="1"
                ref={box6}
                value={code.six}
                onChange={handleNextFocus}
                /> */}
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <input
                type="password"
                className="form-control card-quantity-textbox1 text-center"
                value={password}
                name="password"
                placeholder="Enter Password Here"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          )}

          <div className="text-center">
            <small className="text-danger">{errorMessage}</small>
          </div>
          {pin ? (
            <div className="text-center mt-2">
              <p
                className="text-success"
                style={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => isPin(false)}
              >
                Verify with Password Instead
              </p>
            </div>
          ) : (
            <div className="text-center mt-2">
              <p
                className="text-success"
                style={{ textDecoration: "none", cursor: "pointer" }}
                onClick={() => isPin(true)}
              >
                Verify with Pin Code Instead
              </p>
            </div>
          )}

          <div className="mt-4 continue-btn d-flex justify-content-center">
            {!isVerifying ? (
              <button
                className="btn btn-success btn-next"
                onClick={handleValidation}
              >
                Verify
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-success btn-next"
                disabled
              >
                Please wait...
              </button>
            )}
            {/* <a href="/signup" className="text-success text-resend-color">
          Resend code?
        </a> */}
          </div>
        </>
      ) : (
        <DeliveryAddress />
      )}
    </>
  );
};

export default PinVerification;
