import { Link } from "react-router-dom";
import React, { useState } from "react";
import Spinners from "../../../../components/helpers/Spinners";
import { useCartContext } from "../../../context/CartContext";
import { Skeleton } from "@mui/material";

// import React, { useState } from "react";
// import { useCartContext } from "../../../context/CartContext";
// import { Skeleton } from "@mui/material";

const ProductCard = ({
  id,
  image,
  title,
  client_location,
  amount,
  description,
}) => {
  const { addToCart } = useCartContext();

  const [processing, setProcessing] = useState(false);

  const handleCartProcessing = async () => {
    setProcessing(true);
    const response = await addToCart(id);

    if (response) {
      setProcessing(false);
    }
  };

  return (
    <div className="col-sm-6 col-md-2 mt-2">
      <Link to={`/product/${id}`} className="no-decoration">
        <div className="card card-h-1 me-lg-2">
          <div className="card-img">
            <div
              style={{
                Width: "40vw",
                Height: "7vw",
                objectFit: "cover",
                background: "#EAEAEA",
                borderRadius: "10px",
              }}
            >
              {image ? (
                <img
                  src={image}
                  width="100%"
                  style={{ objectFit: "cover" }}
                  alt="gift-card"
                />
              ) : (
                <Skeleton
                  variant="rectangular"
                  width={210}
                  height={118}
                  className="skeleton-img"
                />
              )}
            </div>

            <div className="d-flex flex-row flex-grow-1 justify-content-between">
              <div className="p-2 w-75">
                <div className="text-left shop-card-text1 mt-2">
                  <h4 style={{ textTransform: "capitalize" }}>{title}</h4>

                  <span className="product-desc">
                    {description?.slice(0, 30) + "..."}
                  </span>

                  <p className="text-left shop-card-text-price amnt-pad mt-2">
                    <h4 className="text-success">
                      <sup>¢</sup>
                      {amount} &nbsp;
                      <sup>.{(amount % 1).toFixed(2).substring(2)}</sup>
                    </h4>
                  </p>
                </div>
              </div>

              <div className="p-1">
                {processing ? (
                  Spinners.rotatingLines
                ) : (
                  <div className="mt-5">
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={handleCartProcessing}
                    >
                      {/* Add your SVG content here */}
                    </svg>
                  </div>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProductCard;
