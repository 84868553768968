import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import Footer from "../partials/Footer";
import UserRate from "./components/UserRate";

function ProductReview() {
  const navigate = useNavigate();
  const [rating, setRating] = useState(null);

  const handleRatingClick = (value) => {
    setRating(value);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className=" mt-4">
            {/* <div className="card-header d-flex flex-row">
              <span>
                <img
                  src="/assets/images/arrow-left.png"
                  onClick={() => navigate(-1)}
                  alt="back"
                />
              </span>
              <div className="subtitle2 text-left text-black mt-2">
                <b>Verified Customer Feedback</b>
              </div>
            </div> */}
            <div className="">
              <div className="d-flex flex-row flex-reverse">
                <div className="">
                  <h5>COMMENTS FROM VERIFIED PURCHASES</h5>
                  <div className="row">
                    <UserRate stars={5} />
                    <hr />
                    <UserRate stars={3} />
                    <hr />
                    <UserRate stars={1} />
                    <hr />
                  </div>
                </div>
                <div className=" ms-5 d-flex flex-column">
                  <p className="text-center">Verified Rating (9)</p>
                  <div className="rating-box align-items-center justify-content-center p-4">
                    <h3 className="text-center">
                      <b>3.8/5</b>
                    </h3>
                    <div className="text-center">
                      <div>
                        {[...Array(5)].map((_, index) => (
                          <span
                            key={index}
                            style={{ cursor: "pointer" }}
                            onClick={() => handleRatingClick(index + 1)}
                            className="stars "
                          >
                            {index < rating ? (
                              <span className="checked">★</span>
                            ) : (
                              "☆"
                            )}
                          </span>
                        ))}
                      </div>
                      {rating && <p>{rating} Verified Rating</p>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default ProductReview;
