import { Skeleton } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useGetFetch from "../../../../components/customHooks/useGetFetch";

function Categories() {
  const navigate = useNavigate();
  const [page] = useState(1);


  const { data: categories, isloaded } = useGetFetch(
    `/prime.sika/v1/markets-places/products-types-stores?page=${page}`
  );

  const handleCategoryClicked = (id) => {
    navigate(`/category/${id}/shops`);
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="subtitle2 text-left">Browse by category</div>
        {isloaded
          ? categories?.shops?.map((category, index) => (
              <CategoriesCard
                id={category?.id}
                color={"#FFFDDB"}
                sideImg={
                  category?.image ||
                  "/assets/images/new/categories/furniture.png"
                }
                title={category?.name}
                handleCategoryClicked={handleCategoryClicked}
              />
            ))
          : Array(6)
              .fill("")
              .map((_, index) => (
                <div className="col-md-4 mt-2" key={index}>
                  <div className="categories-card">
                    <Skeleton
                      variant="rectangular"
                      width={"28vw"}
                      height={"15vw"}
                      sx={{ fontSize: "1.89rem" }}
                    />
                  </div>
                </div>
              ))}
      </div>
    </div>
  );
}

const CategoriesCard = ({
  id,
  color,
  sideImg,
  title,
  handleCategoryClicked,
}) => {
  return (
    <div className="col-md-4 mt-2">
      <div
        className="categories-card"
        onClick={() => handleCategoryClicked(id)}
        style={{
          backgroundImage: `url(${sideImg})`,
          backgroundColor: color,
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <div
          className="d-flex flex-row justify-content-between"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <div>
            <span className={"category-title text-nowrap text-truncate"}>
              {title}
            </span>
            <span className="secondary-text category-merchant-count">
              75+ Merchants
            </span>
            <span>
              <img
                src={`/assets/images/new/categories/logos/merchant.png`}
                className="rounded category-merchant-logo"
                alt={"merchant 1"}
              />
            </span>
            <span>
              <img
                src={`/assets/images/new/categories/logos/merchant2.png`}
                className="rounded category-merchant-logo merchant-logo2"
                alt={"merchant 2"}
              />
            </span>
            <span>
              <img
                src={`/assets/images/new/categories/logos/merchant3.png`}
                className="rounded category-merchant-logo merchant-logo3"
                alt={"merchant 2"}
              />
            </span>
            <span>
              <img
                src={`/assets/images/new/categories/logos/merchant4.png`}
                className="rounded category-merchant-logo merchant-logo4"
                alt={"merchant 2"}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
