import { Skeleton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import useGetFetch from "../../../../components/customHooks/useGetFetch";
import routes from "../../../../components/strings/routes";
import Nav from "../../../../partials/Nav";
import Footer from "../../../../partials/Footer";

function AllMerchants() {
  const { data, isloaded } = useGetFetch("/prime.sika/v1/markets-places/list");

  const navigate = useNavigate();

  return (
    <>
      <Nav />
      <div className="container">
        <div className="row">
          <div className="mt-4">
            <div className="d-flex flex-md-row flex-sm-column justify-content-between">
              <div className="shop-text-header2">
                <h2>Explore Merchants</h2>
              </div>

              {/* <div className="carousel slide">
              <a
                href="#bod"
                role="button"
                data-bs-slide="prev"
                onClick={() => ref?.current?.slickPrev()}
              >
                <i
                  className="fa fa-long-arrow-left fa-1x arrow-x-left arrow-x rounded-circle px-1 py-1 bg-white prev"
                  aria-hidden="true"
                ></i>
              </a>
              &nbsp;
              <a
                href="#bod"
                role="button"
                data-bs-slide="next"
                onClick={() => ref?.current?.slickNext()}
              >
                <i
                  className="fa fa-long-arrow-right fa-1x arrow-x-right rounded-circle px-1 py-1 text-white next"
                  aria-hidden="true"
                ></i>
              </a>
            </div> */}
            </div>

            <p className="text-left text-left1 shop-text-desc2">
              For the unlimited shopping spree, select any of our Prime eGift
              cards, Give your recepient the choice to purchase from any Prime
              participating merchant
            </p>
          </div>
        </div>

        <div className="my-auto">
          <div className="row">
            {isloaded
              ? data?.shops?.map((shop, index) => (
                  <div className="col-md-3 col-12 mt-2" key={index}>
                    <ShopCard
                      id={shop?.id}
                      image={shop?.image || "/assets/images/rounded-3.png"}
                      title={shop?.name}
                    />
                  </div>
                ))
              : Array(5)
                  .fill("")
                  .map((_, index) => (
                    <div className="col-md-3 col-12 mx-2 mt-3" key={index}>
                      <div className="card card-h-1 me-2">
                        <div className="card-img d-flex flex-row align-items-center gap-2 mb-2">
                          <Skeleton
                            variant="circular"
                            width="5vw"
                            height="5vw"
                          />
                          <Skeleton
                            variant="rectangular"
                            width={"5vw"}
                            height={"1vw"}
                            sx={{ fontSize: "1.89rem" }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
          </div>
          <div className="row d-flex justify-content-center mt-4">
            <div
              className="viewall text-white align-items-center justify-content-center d-flex"
              onClick={() => navigate(routes.INITIAL_ROUTE)}
              style={{ height: "49px", width: "215px" }}
            >
              Minimize
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

const ShopCard = ({ image, title, id }) => {
  return (
    <Link to={`/shop/${id}`} style={{ textDecoration: "none" }}>
      <div className="card p-0  mx-2 shops-card">
        <div className="d-flex flex-row  mt-2 mb-2">
          <div
            className="ms-3"
            style={{
              backgroundImage: `url(${
                image || "/assets/images/rounded-3.png"
              })`,
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              backgroundSize: "cover",
            }}
          ></div>
          <div className="text-left shop-card-text1 mt-2 ms-3">
            <h4
              style={{ textTransform: "capitalize", overflowX: "scroll" }}
              className="text-truncate"
              title={title}
            >
              {title}
              <span className="d-flex flex-row secondary-text gap-1">
                <img
                  src="/assets/images/location.png"
                  className="img-fluid"
                  alt="location"
                />
                15min
              </span>
            </h4>
          </div>
        </div>
      </div>
    </Link>
  );
};
export default AllMerchants;
