import { Skeleton } from "@mui/material";
import React from "react";
import Advertisement from "../components/advertisement/Advertisement";
import Advertisement2 from "../components/advertisement/Advertisment2";
import BestDeals from "../components/BestDeals";
import NewProducts from "../components/NewProducts";
import ProductCard from "../components/ProductCard";

function Products({ shopProducts, latestProducts }) {
  return (
    <>
      <div className="container-fluid">
        {/* <FilteredProducts products={items?.products} searchParam={searchParam} /> */}

        <NewProducts latestProducts={latestProducts} />

        <BestDeals />

        <Advertisement />

        {/* New on Prime */}

        {/* eGift Cards from top brands */}
        <div className="container-fluid">
          <div className="row">
            <div className="shop-text-header mt-4">
              <h2>More Products</h2>
            </div>

            {/* <div className="row"> */}
            {shopProducts?.length > 0
              ? shopProducts?.slice(0, 12).map((product, index) => {
                  return (
                    <div
                      className="col-6 col-md-2 col-lg-2 mt-2   mt-2"
                      key={index}
                    >
                      <ProductCard
                        id={product?.code}
                        image={product?.pictures[0]}
                        title={product?.name}
                        description={product?.description}
                        client_location={product?.shop?.location || "N/A"}
                        amount={`${product?.price}`}
                      />
                    </div>
                  );
                })
              : Array(6)
                  .fill("")
                  .map((_, index) => (
                    <div className="col-6 col-md-2 col-lg-2  mt-2" key={index}>
                      <div className="card card-h-1 me-2">
                        <div className="card-img">
                          <Skeleton
                            variant="rectangular"
                            width="11vw"
                            height="7vw"
                          />
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "0.89rem" }}
                          />
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "0.89rem" }}
                            className="mt-2"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
          </div>
        </div>

        <Advertisement2 />
        <div className="row mt-3">
          <div className="shop-text-header mt-4">
            <h2>More Products</h2>
          </div>

          {shopProducts?.length > 0
            ? shopProducts?.slice(13)?.map((product, index) => {
                return (
                  <div className="col-6  col-md-2  mt-2" key={index}>
                    <ProductCard
                      id={product?.code}
                      image={product?.pictures[0]}
                      title={product?.name}
                      description={product?.description}
                      client_location={product?.shop?.location || "N/A"}
                      amount={`${product?.price}`}
                    />
                  </div>
                );
              })
            : Array(6)
                .fill("")
                .map((_, index) => (
                  <div className="col-6 col-md-2 col-lg-2  mt-2" key={index}>
                    <div className="card card-h-1 me-2">
                      <div className="card-img">
                        <Skeleton
                          variant="rectangular"
                          width="11vw"
                          height="7vw"
                        />
                        <Skeleton variant="text" sx={{ fontSize: "0.89rem" }} />
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "0.89rem" }}
                          className="mt-2"
                        />
                      </div>
                    </div>
                  </div>
                ))}
        </div>
      </div>
    </>
  );
}

export default Products;
