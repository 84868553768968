import React, { useState } from "react";
import NewMap from "./NewMap";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import routes from "../../components/strings/routes";
import { useNavigate } from "react-router-dom";
import useGetFetch from "../../components/customHooks/useGetFetch";
import axios from "axios";
import Button from "../../components/Button";
import toast from "react-hot-toast";

const DeliveryAddress = () => {
  const [userInput, setUserInput] = useState({});
  const navigate = useNavigate();
  const [location, setLocation] = useState({
    long: "",
    lat: "",
    adddress: "",
  });

  const [loading, setLoading] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [phone, setPhone] = useState(localStorage.getItem("phone") || "");
  const [newAddress, setNewAddress] = useState(false);
  const { data } = useGetFetch("prime.sika/v1/customers/addresses");

  const getMapLocation = (value) => {
    console.log(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("prime.sika/v1/customers/address", {
        full_name: userInput?.full_name,
        phone_number: phone,
        address: location?.address,
        residential_address: location?.address,
        longitude: location?.lng,
        latitude: location?.lat,
      })
      .then((res) => {
        axios.get("prime.sika/v1/customers/addresses").then((res) => {
          localStorage.setItem(
            "delivery_id",
            res?.data?.data?.addresses?.[0]?.id
          );
          navigate(routes.PAYMENT_OPTIONS);
        });
      })
      .catch((err) => {
        console.log(err?.response);
        toast.error("An Error Occured", { duration: 8000 });
      })
      .finally((_) => setLoading(false));
  };

  const handleUserInput = (e) => {
    setUserInput((userInput) => ({
      ...userInput,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="card">
      <div className="card-header">Delivery Details</div>
      <div className="card-body">
        <div className="form-group ">
          <select
            className="form-control  text-left"
            name="address"
            onChange={(e) => {
              const value = e.target.value;
              value === "other" ? setNewAddress(true) : setNewAddress(false);
              if (value !== "" && value !== "other") {
                const address = data?.addresses?.find(
                  (item) => Number(item?.id) === Number(value)
                );
                localStorage.setItem("delivery_id", address?.id);
                console.log(address);
                setSelectedAddress(address);
              }
            }}
          >
            <option value={""}>Select Delivery Address</option>
            <option value={"other"}>Add New Address</option>
            {data?.addresses?.map((item, index) => (
              <option key={index} value={item?.id}>
                <div className="card">{item?.residential_address_name}</div>
              </option>
            ))}
          </select>

          {/* <div className="text-danger">{errors.business_name}</div> */}
        </div>
        {newAddress ? (
          <form onSubmit={handleSubmit}>
            <div className="form-group mt-4">
              <input
                type="text"
                className="form-control textbox-size2 text-left"
                placeholder="Enter your  name here"
                value={userInput.full_name}
                name="full_name"
                onChange={handleUserInput}
                required
              />
              {/* <div className="text-danger">{errors.business_name}</div> */}
            </div>
            {/* Map Modal */}

            <div className="col-md-12 text-left mb-2 mt-2">
              <label htmlFor="phone" className=" text-left">
                Phone Number
              </label>

              <PhoneInput
                country="GH"
                defaultCountry="GH"
                // countries={['GH']}
                value={phone}
                onChange={setPhone}
                className="form-control card-quantity-textbox d-flex"
                placeholder="phone"
              />
              <div className="text-danger">
                {phone
                  ? isValidPhoneNumber(phone)
                    ? ""
                    : "Invalid phone number"
                  : "Phone number required"}
              </div>
            </div>
            <NewMap getMapLocation={getMapLocation} setLocation={setLocation} />
            <div className=" mb-3 d-flex justify-content-center mt-2">
              <Button
                cssClasses={"btn btn-success text-center form-control"}
                buttonText={"Confirm & Continue"}
                isloading={loading}
              />
            </div>
          </form>
        ) : (
          <>
            <div className="card mt-2">
              <div className="card-header">Address Details</div>
              <div className="card-body">
                Name: {selectedAddress?.full_name}
                <br />
                Phone: {selectedAddress?.phone_number}
                <br />
                Address: {selectedAddress?.cordinates?.address}
              </div>
            </div>
            <div className=" mb-3 d-flex justify-content-center mt-2">
              <button
                type="button"
                className="btn btn-success text-center form-control"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  if (selectedAddress.length === 0) {
                    window.alert("Please Select Delivery Address");
                  } else {
                    localStorage.setItem("delivery_id", selectedAddress?.id);
                    navigate(routes.PAYMENT_OPTIONS);
                  }
                }}
              >
                Confirm & Continue
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DeliveryAddress;
