import { Skeleton } from "@mui/material";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

function SubCategories({isloaded,category}) {
  // const {data}=useGetFetch('/prime.sika/v1/markets-places/list');
  const ref=useRef();
   
    const settings = {
        infinite: true,
        autoplay: true,
        variableWidth: true,
        arrows:false,
        speed: 500,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              // slidesToShow: 3,
              // slidesToScroll: 3,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              // slidesToShow: 2,
              // slidesToScroll: 2,
              variableWidth: true,
              dots: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              variableWidth: false,
              dots: false,
            },
          },
        ],
      };
    
  return (
    <div className="container">
      <div className="row">
        <div className="mt-4">
          <div className="d-flex flex-md-row flex-sm-column justify-content-between">
            <div className="shop-text-header2">
              <h2 className="text-capitalize">{category?.name}</h2>
            </div>

            <div className="carousel slide">
              <a href="#bod" role="button" data-bs-slide="prev" onClick={()=>ref?.current?.slickPrev()}>
                <i
                  className="fa fa-long-arrow-left fa-1x arrow-x-left arrow-x rounded-circle px-1 py-1 bg-white prev"
                  aria-hidden="true"
                ></i>
              </a>
              &nbsp;
              <a href="#bod" role="button" data-bs-slide="next" onClick={()=>ref?.current?.slickNext()}>
                <i
                  className="fa fa-long-arrow-right fa-1x arrow-x-right rounded-circle px-1 py-1 text-white next"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="my-auto justify-content-between mt-3">
      <Slider {...settings} ref={ref}>
        {isloaded
          ? category?.map((subcategory, index) => (
              <div className="col-md-3 col-4" key={index}>
                <ShopCard
                  id={subcategory?.id}
                  image={subcategory?.image || "/assets/images/rounded-3.png"}
                  title={subcategory?.name}

                />
              </div>
            ))
          : Array(5)
              .fill("")
              .map((_, index) => (
                <div className=" mx-2 mt-3" key={index}>
                  <div className="card card-h-1 me-2">
                    <div className="card-img d-flex flex-row align-items-center gap-2 mb-2">
                      <Skeleton
                        variant="circular"
                        width="5vw"
                        height="5vw"
                      />
                      <Skeleton variant="rectangular" width={'5vw'} height={'1vw'}  sx={{ fontSize: "1.89rem" }} />
                    </div>
                  </div>
                </div>
              ))}
      </Slider>
    </div>
    </div>)
}

const ShopCard = ({ image, title, id }) => {
  return (
    <Link to={`/shop/${id}`} style={{ textDecoration: "none" }}>
      <div className="card p-0  mx-2 shops-card">
        <div className="d-flex flex-row  mt-2 mb-2">
          <div className="ms-3" style={{backgroundImage:`url(${image|| "/assets/images/rounded-3.png"})`,width:'60px',height:'60px', borderRadius:'50%',backgroundSize:'cover'}}>

          </div>
          <div className="text-left shop-card-text1 mt-2 ms-3">
            <h4 style={{ textTransform: "capitalize" }} title={title}>
              {title}
              <span className="d-flex flex-row secondary-text gap-1">
                <img src="/assets/images/location.png" className="img-fluid" alt="location" />
                15min
              </span>
            </h4>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SubCategories
