import React from "react";

function ProductSearchInput({ setSearchParam, setCategoryId }) {
  return (
    <div className="col-12 col-lg-4">
      <div className="row">
        <form>
          <div className="d-flex align-items-center bg-white px-2 border rounded-pill">
            <div
              className="d-flex justify-content-center align-items-center bg-green rounded-circle"
              style={{ width: "44px", height: "42px" }}
            >
              <img
                src="/assets/images/search-normal.svg"
                className="mx-auto img-rounded"
                alt="search"
              />
            </div>
            <input
              type="text"
              placeholder="Search here..."
              className="form-control border-none rounded-pill search-left px-2 py-3 search-font-width"
              id="no_border"
              onChange={(e) => {
                setSearchParam(e.target.value);
                setCategoryId("");
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProductSearchInput;
