import React from "react";
import { elastic as Menu } from "react-burger-menu";
import Separator from "./Separator";
import "./sidebar.css";
import { Link, useLocation } from "react-router-dom";

function SideBar() {
  const location = useLocation();

  return (
    <>
      <div className="d-flex justify-content-center flex-column sticky-lg-top"></div>
      <Menu>
        <Link
          className={`menu-item ${location.pathname === "/" && "nav-active"}`}
          to="/"
        >
          <NavItem iconUrl={"/assets/images/new/shop.png"} title={"Shop"} />
        </Link>
        <Link
          className={`menu-item ${
            location.pathname === "/orders" && "nav-active"
          }`}
          to="/orders"
        >
          <NavItem
            iconUrl={"/assets/images/new/receipt-2.png"}
            title={"My Orders"}
          />
        </Link>
        <Link
          className={`menu-item ${
            location.pathname === "/cart" && "nav-active"
          }`}
          to="/cart"
        >
          <NavItem
            iconUrl={"/assets/images/new/shopping-cart.png"}
            title="My Cart"
          />
        </Link>
        <Separator text={"Credits and Promos"} />
        <a className="menu-item" href="/pizzas">
          <NavItem
            iconUrl={"/assets/images/new/dollar-circle.png"}
            title="Invite Friends, Earn Money"
          />
        </a>
        <a className="menu-item" href="/pizzas">
          <NavItem
            iconUrl={"/assets/images/new/card.png"}
            title="Buy E-gift Card"
          />
        </a>
        <a className="menu-item" href="/pizzas">
          <NavItem
            iconUrl={"/assets/images/new/tag-2.png"}
            title="Credit, Promos & Gift Cards"
          />
        </a>
        <Separator text={"Support"} />
        <a className="menu-item" href="/pizzas">
          <NavItem
            iconUrl={"/assets/images/new/info-circle.png"}
            title="Help Center"
          />
        </a>
        <a className="menu-item" href="/pizzas">
          <NavItem
            iconUrl={"/assets/images/new/message-question.png"}
            title="How Prime Plus Works"
          />
        </a>
        <Separator text={"Our Apps"} />
        <a className="menu-item" href="/pizzas">
          <NavItem iconUrl={"/assets/images/new/apple.png"} title="App Store" />
        </a>
        <a className="menu-item" href="/pizzas">
          <NavItem
            iconUrl={"/assets/images/new/google-play.png"}
            title="Google Play"
          />
        </a>
      </Menu>
    </>
  );
}

const NavItem = ({ iconUrl, title }) => {
  return (
    <div className="d-flex flex-row align-items-start gap-2 nav-outer">
      <div className="nav-inner">
        <img src="/assets/images/new/nav_yellow.png" alt="nav_yellow" />
      </div>
      <img
        src={iconUrl}
        className="img-fluid"
        alt="wishlist-icon"
        width={"12%"}
      />
      <span className="nav-text mt-1">{title}</span>
    </div>
  );
};

export default SideBar;
