import React from "react";
import { useCartContext } from "../../context/CartContext";
import QuantitySelector from "./QuantitySelector";

function CartItems() {
  const { deleteFromCart, clearCart, cart } = useCartContext();
  const handleClearCart = (e) => {
    e.preventDefault();
    e.target.value = "Clearing Cart...";
    clearCart();
    e.target.value = "Clear";
  };

  return (
    <div className=" rounded-2">
      <div className="card-header">
        <div className="d-flex flex-row justify-content-between shop-text-header2">
          <h2 className="my-auto">My Cart</h2>

          <button
            className="btn btn-outline-success px-5 py-2 me-2"
            onClick={handleClearCart}
          >
            Clear All
          </button>
        </div>
      </div>
      <div className="card-table">
        <div className="card-body booking_card">
          <div className="table-responsive">
            {Number(cart?.shop_cart?.items?.length) > Number(0) ? (
              <table className="datatable table table-stripped table table-hover table-center mb-0">
                <tbody>
                  {cart?.shop_cart?.items?.map((item) => (
                    <tr key={item?.id}>
                      <td width="30%">
                        <img
                          src={item?.product?.pictures?.[0]}
                          alt="Card"
                          width="70%"
                          style={{ borderRadius: "10px" }}
                        />
                      </td>
                      <td>
                        <div className="subtitle2 text-left text-capitalize">
                          {item?.product?.name}
                        </div>
                        <div className="total-amount">¢ {item?.amount}</div>
                      </td>
                      <td>
                        <QuantitySelector
                          value={item?.quantity}
                          id={item?.product?.id}
                        />
                      </td>
                      <td>
                        <button className="btn btn-outline-success">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-x"
                              onClick={() => deleteFromCart(item?.id)}
                            >
                              <line x1="18" y1="6" x2="6" y2="18" />
                              <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                          </span>
                          <span className="bi bi-x"></span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <h1>Your Cart is Empty</h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CartItems;
