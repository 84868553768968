import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const SliderWithImages = ({images}) => {
  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoPlay:true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow:<img src={'/assets/img/arrow-left.png'} alt="Previous" />,
    nextArrow:<img src={'/assets/img/arrow-right.png'} alt="Previous" />,
    afterChange: (current) => setActiveSlide(current),
  };

  const handleImageClick = (index) => {
    setActiveSlide(index);
    sliderRef.current.slickGoTo(index);
  };

  return (
    <div>
      <Slider {...settings} ref={sliderRef}>
        {images?.map((image,index) => (
          <div key={index} style={{width:'50px',height:'50px'}}>
            <img src={image} style={{objectFit:'cover'}} className='img-fluid' alt={`Slide ${image.id}`} />
          </div>
        ))}
      </Slider>
      <div className="slider-images">
        {images?.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index}`}
            className={`${index === activeSlide ? 'active' : ''} m-2`}
            onClick={() => handleImageClick(index)}
            width={'20%'}
          />
        ))}
      </div>
    </div>
  );
};

export default SliderWithImages;
