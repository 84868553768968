
import Footer from "../../partials/Footer";
import Nav from "../../partials/Nav";
import BillingDetails from "./components/BillingDetails";
import CartItems from "./components/CartItems"
import { useCartContext } from "../context/CartContext";
import { useEffect } from "react";
import UserVerification from "../checkout/UserVerification";

const Cart = () => {
  const { getCartItems, cart } = useCartContext();

  useEffect(() => {
    getCartItems();
  }, []);

  return (
    <>
    <Nav/>
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-8">
          <CartItems cart={cart}/>
        </div>
        <div className="col-md-4">
          <BillingDetails cart={cart}/>
          {/* <UserVerification/> */}
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Cart;
