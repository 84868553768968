import { Skeleton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import React, { useRef } from "react";
import Slider from "react-slick";
import useGetFetch from "../../../../components/customHooks/useGetFetch";
import routes from "../../../../components/strings/routes";

function Merchants() {
  const { data, isloaded } = useGetFetch("/prime.sika/v1/markets-places/list");
  const ref = useRef();
  const navigate = useNavigate();

  const settings = {
    infinite: true,
    autoplay: true,
    variableWidth: true,
    arrows: false,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          // slidesToShow: 3,
          // slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          // slidesToShow: 2,
          // slidesToScroll: 2,
          variableWidth: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="container">
      <div className="row">
        <div className="mt-4">
          <div className="d-flex flex-md-row flex-sm-column justify-content-between">
            <div className="shop-text-header2 text-sm-font">
              <h2>Explore Merchants</h2>
            </div>

            <div className="carousel slide">
              <a
                href="#bod"
                role="button"
                data-bs-slide="prev"
                onClick={() => ref?.current?.slickPrev()}
              >
                <i
                  className="fa fa-long-arrow-left fa-1x arrow-x-left arrow-x rounded-circle px-1 py-1 bg-white prev"
                  aria-hidden="true"
                ></i>
              </a>
              &nbsp;
              <a
                href="#bod"
                role="button"
                data-bs-slide="next"
                onClick={() => ref?.current?.slickNext()}
              >
                <i
                  className="fa fa-long-arrow-right fa-1x arrow-x-right rounded-circle px-1 py-1 text-white next"
                  aria-hidden="true"
                ></i>
              </a>
            </div>
          </div>

          <p className="text-left text-left1 shop-text-desc2 text-sm-display">
            For the unlimited shopping spree, shop online with your E-Gift card.
          </p>
        </div>
      </div>

      <div className="my-auto">
        <div className="row">
          <div className="col-md-9">
            <div>
              <Slider {...settings} ref={ref}>
                {isloaded
                  ? data?.shops?.map((shop, index) => (
                      <div className="col-md-3 col-4" key={index}>
                        <ShopCard
                          id={shop?.id}
                          image={
                            shop?.logo_url || "/assets/images/rounded-3.png"
                          }
                          title={shop?.name}
                        />
                      </div>
                    ))
                  : Array(5)
                      .fill("")
                      .map((_, index) => (
                        <div className=" mx-2 mt-3" key={index}>
                          <div className="card card-h-1 me-2">
                            <div className="card-img d-flex flex-row align-items-center gap-2 mb-2">
                              <Skeleton
                                variant="circular"
                                width="5vw"
                                height="5vw"
                              />
                              <Skeleton
                                variant="rectangular"
                                width={"5vw"}
                                height={"1vw"}
                                sx={{ fontSize: "1.89rem" }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
              </Slider>
            </div>
          </div>
          <div className="col-md-2">
            <div
              className="viewall text-white align-items-center justify-content-center d-flex mt-md-3"
              onClick={() => navigate(routes.ALL_SHOPS)}
            >
              View All
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ShopCard = ({ image, title, id }) => {
  return (
    <Link to={`/shop/${id}`} style={{ textDecoration: "none" }}>
      {/* <div className="card mx-2 shops-card">
        <div className="d-flex flex-row  mt-2 mb-2">
          <div className="ms-3" style={{backgroundImage:`url(${image|| "/assets/images/rounded-3.png"})`,width:'60px',height:'60px', borderRadius:'50%',backgroundSize:'cover'}}>

          </div>
          <div className="text-left shop-card-text1 mt-2 ms-3">
            <h4 style={{ textTransform: "capitalize" }} title={title}>
              {title}
              <span className="d-flex flex-row secondary-text gap-1">
                <img src="/assets/images/location.png" className="img-fluid" alt="location" />
                15min
              </span>
            </h4>
          </div>
        </div>
      </div> */}
      <div className="d-flex mb-3">
        <div className="category-cover mt-md-3 me-3">
          <div className="category-img-cover">
            <img src={image} alt="" />
          </div>
          <div className="d-block category-name-font mt-1 ms-2">
            <div>
              <h4 className="text-capitalize">{title.toLowerCase()}</h4>
            </div>
            <div className="d-flex">
              <img
                src="/assets/images/location.png"
                className="img-fluid"
                alt="location"
                width="20px"
              />

              <h5 className="my-auto">15min</h5>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
export default Merchants;
