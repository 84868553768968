import React, { useState } from "react";

function UserRate({ stars }) {
  const [rating, setRating] = useState(stars);

  const handleRatingClick = (value) => {
    setRating(value);
  };

  return (
    <div>
      <div>
        {[...Array(stars)].map((_, index) => (
          <span
            key={index}
            style={{ cursor: "pointer" }}
            onClick={() => {}}
            className="stars"
          >
            {index < rating ? <span className="checked">★</span> : "☆"}
          </span>
        ))}
      </div>
      <b>Product Name Here</b>
      <p>User Comment and many other suggestions Goes Here</p>
      <p className="text-faint">24-03-2022 by Samuel</p>
    </div>
  );
}

export default UserRate;
