import React, { createContext, useContext } from "react";
import axios from "axios";

export const CheckoutContext = createContext({
  hubtelPayment: () => {},
  walletPayment: () => {},
  primeCardPayment: () => {},
  getPrimeWalletAmount: () => {},
  inStorePickUp: () => {},
  otpValidation: () => {},
});

export const CheckoutContextProvider = ({ children }) => {
  const hubtelPayment = (cart_id) => {
    return axios
      .post("/prime.sika/v1/shop-payments/customer/pay", {
        payment_option_code: "mobile_money_or_bank_payment",
        product_cart_id: cart_id,
        telephone: localStorage.getItem("phone"),
        delivery_address_id: localStorage.getItem("delivery_id"),
      })
      .then((res) => {
        if (res.data.status === 201 && res.data.success === true) {
          return res.data.data.payment_url;
        }
      })
      .catch((err) => {
        console.log(err.response);
        return err?.response;
      });
  };

  const primeCardPayment = (cart_id, card_code) => {
    return axios
      .post("/prime.sika/v1/shop-payments/customer/pay", {
        product_cart_id: cart_id,
        payment_option_code: "prime_merchant_card_payment",
        confirmation_code: localStorage.getItem("otp"),
        card_payment_code: card_code,
        delivery_address_id: localStorage.getItem("delivery_id"),
      })
      .then((res) => {
        if (res.data.success === true) {
          return res.data;
        }
      })
      .catch((err) => {
        console.log(err.response);
        return err?.response;
      });
  };

  const otpValidation = (phone, verificationCode) => {
    return axios
      .post("/prime.sika/v1/customers/guest-signup", {
        telephone: phone,
        user_type: "customer",
        otp: verificationCode,
      })
      .then((res) => {
        localStorage.setItem("otp_verified", true);
        localStorage.setItem("otp", verificationCode);
        if (res.data.status === 200 && res.data.success === true) {
          console.log(res.data);
          localStorage.setItem("token", res.data.data.token);
          return res.data;
        } else if (res.data.status === 201) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response;
      });
  };

  const walletPayment = (cart_id, wallet_id) => {
    // prime.sika/v1/accounts/customer/prime
    return axios
      .post("/prime.sika/v1/shop-payments/customer/pay", {
        product_cart_id: cart_id,
        payment_option_code: "prime_wallet_card_payment",
        prime_wallet_id: wallet_id,
        delivery_address_id: localStorage.getItem("delivery_id"),
      })
      .then((res) => {
        if (res.data.success === true) {
          return res.data;
        }
      })
      .catch((err) => {
        console.log(err.response);
        return err?.response;
      });
  };

  const getPrimeWalletAmount = () => {
    return axios
      .get("/prime.sika/v1/accounts/customer/prime")
      .then((res) => {
        if (res.data.success === true) {
          return res.data;
        }
      })
      .catch((err) => {
        console.log(err.response);
        return err?.response;
      });
  };

  const value = {
    hubtelPayment,
    otpValidation,
    primeCardPayment,
    walletPayment,
    getPrimeWalletAmount,
  };

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutContext;

export const useCheckoutContext = () => {
  const {
    hubtelPayment,
    otpValidation,
    primeCardPayment,
    walletPayment,
    getPrimeWalletAmount,
  } = useContext(CheckoutContext);

  return {
    hubtelPayment,
    otpValidation,
    primeCardPayment,
    walletPayment,
    getPrimeWalletAmount,
  };
};
