import React from "react";
import { useNavigate, useParams } from "react-router-dom";

function HubtelPortal() {
    const {url}=useParams();
    const navigate=useNavigate();
  return (
    <>
      <div className="container">
        <div className="row mt-4">
            <div className="text-center">
            <img src="/assets/images/logo.png" alt="logo" width={'10%'} className="img-fluid"/>
            </div>
            <div className="col-md-2"></div>
          <div className="col-md-8">
            <div className="card rounded">
              <div className="card-header shop-card-text1 d-flex flex-row align-items-center">
              <img src="/assets/images/arrow-left.png" alt="logo" width={"5%"} onClick={()=>navigate(-1)} style={{cursor:'pointer'}}  className="img-fluid"/>
                <h4 className="mt-2">Mobile Money or Bank Card</h4>
              </div>
              <div>
                <iframe
                  src={decodeURIComponent(url)}
                  title="Hubtel Payment Portal"
                  width="100%"
                  height="500px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HubtelPortal;
