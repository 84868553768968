

const routes={
    INITIAL_ROUTE:'/',
    PRIMEWEBSITE_HOME:"https://primeegiftcard.com/home",
    PRIMEWEBSITE_SHOP_CARD:"https://primeegiftcard.com",
    PRIMEWEBSITE_BUSINESS:"https://primeegiftcard.com/business",
    PRIMEWEBSITE_MERCHANT:"https://primeegiftcard.com/merchant",
    PRIMEWEBSITE_AFFILIATE:"https://primeegiftcard.com/affiliate",
    PRIMEWEBSITE_ABOUT_US:"https://primeegiftcard.com/about",
    PRIMEWEBSITE_CUSTOMER_CARE:"https://primeegiftcard.com/customer-care",

    SHOPS_PAGE:'/category/:id/shops',
    ALL_SHOPS:'/category/all',
    SHOP_DETAILS_PAGE:'/shop/:id',
    PRODUCT_DETAILS_PAGE:'/product/:code',

    PRODUCT_REVIEW:'/reviews',
    ADD_RATING:'/add-rating',
    CART:'/cart',
    CHECKOUT:'/checkout',
    OTP_VALIDATION:'/otp-validation',
    PAYMENT_OPTIONS:'/payment',
    HUBTEL_PORTAL:'/hubtel/:url',
    GIFT_CARD_PAYMENT:'/giftcard',
    ORDERS:'/orders',
}

export default routes;