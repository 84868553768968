import React from "react";
import { useNavigate } from "react-router-dom";

function Shops({ shops, isloaded }) {
  const navigate = useNavigate();

  const handleShopClicked = (id) => {
    navigate(`/shop/${id}`);
  };


  return (
    <div className="container mt-4">
      <div className="row">
        {shops?.map((shop, index) => (
          <ShopCard
            key={index}
            id={shop?.id}
            title={shop?.name}
            bgImg="/assets/images/new/shops/shop1.png"
            logo={`${
              shop?.logo_url ||
              "/assets/images/new/categories/logos/merchant.png"
            }`}
            handleShopClicked={handleShopClicked}
          />
        ))}
      
      </div>
    </div>
  );
}

const ShopCard = ({ id, color, bgImg, logo, title, handleShopClicked }) => {

  return (
    <div className="col-md-4 mt-2">
      <div
        className="categories-card"
        onClick={() => handleShopClicked(id)}
        style={{
          backgroundImage: `url(${bgImg})`,
          // backgroundSize: "100% 100%",
          backgroundPosition: "center",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <div
          className="d-flex flex-row justify-content-between"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%),",
          }}
        >
          <div>
            <span className={"shop-title text-nowrap text-truncate text-capitalize"}>
              {title}
            </span>
            <span className="secondary-text shop-merchant-count text-capitalize">
              Furniture. Wholesale. Deals
            </span>
            <span className="secondary-text delivery text-capitalize">
              Delivery by Day | In-store Prices
            </span>
            <span>
              <div
                className="shop-merchant-logo"
                style={{
                  backgroundImage: `url(${logo})`,
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundSize: "cover",
                }}
              ></div>
            
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shops;
