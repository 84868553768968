import { Link, useLocation } from "react-router-dom";
import React from "react";
import routes from "../components/strings/routes";
import ProductSearchInput from "./ProductSearchInput";

const Nav = () => {
  const location = useLocation();
  const navList = [
    {
      name: "Shop E-gift card",
      to: routes.PRIMEWEBSITE_SHOP_CARD,
    },
    {
      name: "Marketplace",
      to: routes.INITIAL_ROUTE,
    },
    {
      name: "About Us",
      to: routes.PRIMEWEBSITE_ABOUT_US,
    },
  ];

  return (
    <div className="container-fluid bg-green sticky-lg-top nav-z-index">
      <nav className="navbar navbar-expand-sm navbar-light bg-green flex-sm-nowrap flex-wrap nav-bg container">
        <div className="container-fluid">
          <span className="navbar-brand flex-grow-1 d-flex flex-row align-items-center">
            <div>
              {/* <img src="/assets/images/new/hamburger.png" width={'70%'} alt="logo"/> */}
            </div>
            <Link to={routes.INITIAL_ROUTE}>
              <img
                src="/assets/images/new/logo-white.png"
                alt="logo"
                width={"20%"}
                className="ms-4"
              />
            </Link>
          </span>
          <a
            href="void(0)"
            className="navbar-toggler flex-grow-sm-1 flex-grow-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar5"
          >
            <span className="navbar-toggler-icon"></span>
          </a>
          <div
            className="navbar-collapse nav-responsive collapse flex-grow-1 justify-content-start align-items-center"
            id="navbar5"
          >
            <ul className="navbar-nav">
              {navList.map((el, index) => (
                <li
                  className={`nav-item ms-3 me-3 ${
                    location.pathname === el.to && "active"
                  }`}
                  key={index}
                >
                  <Link to={el.to} className="nav-link">
                    {el.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <ProductSearchInput />
        </div>
      </nav>
    </div>
  );
};

export default Nav;
